import $ from 'jquery';
import protip from 'protip';

// это тоже самое, что и $(document).ready(function(){ ... });
// в документации: https://www.npmjs.com/package/protip
// но устарело, поэтому юзай эту конструкцию
$(function() {
    $.protip({
        selector: '.js-tooltip',
    });
});

// console.log(protip);