import $ from 'jquery';

/**
 * QuestionnaireFinalV1 widget configs
 */
const questionnaireFinalV1Semantics = {
    'mainWrapClass': 'questionnaire-final-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'finalButton': 'questionnaire-final-v1__btn',
};

/**
 * QuestionnaireFinalV1 initializer
 */
$(function () {
    QuestionnaireFinalV1.init();
});

class QuestionnaireFinalV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${questionnaireFinalV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.questionnaireFinalV1) {
            window.widgets.questionnaireFinalV1 = new QuestionnaireFinalV1();
        }

        /**
         * Checking other instances
         */
        $(`.${questionnaireFinalV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${questionnaireFinalV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.questionnaireFinalV1.registerInstance($(el));
                window.widgets.questionnaireFinalV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${questionnaireFinalV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            // TODO[dependDetected]: slick plugin
            let object = el.find(`.${questionnaireFinalV1Semantics.mainWrapClass}`);
            el.attr(`${questionnaireFinalV1Semantics.isInitializedAttribute}`, true);
            window.widgets.questionnaireFinalV1.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {

    }

    // метод для подставновки финальной ссылки
    setFinalLink(instanceId, finalLink) {
        $(`[data-instance-id="${instanceId}"]`)
            .find(`.${questionnaireFinalV1Semantics.finalButton}`)
            .attr('href', finalLink);
    }

    reInit(instanceId) {
        QuestionnaireFinalV1.init();
    }
}