import $ from 'jquery';

const reviewAddButtonV1Semantics = {
    mainWrapClass: 'review-add-button-v1',
    isInitializedAttribute: 'data-is-initialized',
    instanceIdAttribute: 'data-instance-id',
    elementClass: 'review-add-button-v1__add-button',
    //Dependencies
    popupContainerClass: 'review-modal-v1_popup',
    ownerModuleClass: 'review-modal-v1__owner-module',
    ownerTypeClass: 'review-modal-v1__owner-type',
    ownerIdClass: 'review-modal-v1__owner-id',
    errorMessageBlock: 'review-modal-v1__error',
    errorImageLimitMessageBLock: 'review-modal-v1__image-limit',
    successMessageBlock: 'review-modal-v1__success',
    hideClass: 'review-modal-v1__hide',
    formClass: 'review-modal-v1__form',
    raitStar: 'rating-star-vote-v1__star-item',
}

$(function () {

    let roots = $(`.${reviewAddButtonV1Semantics.mainWrapClass}`);
    if (roots.length === 0) {
        return false;
    }
    let reviewAddButtonV1 = new ReviewAddButtonV1();
    /**
     * Checking other instances
     */
    roots.each((index, el) => {
        let isInitialized =
            ($(el).attr(`${reviewAddButtonV1Semantics.isInitializedAttribute}`) === 'true');

        if (!isInitialized) {
            reviewAddButtonV1.registerInstance($(el));
            reviewAddButtonV1.registerListeners($(el));
        }
    });
})

class ReviewAddButtonV1 {

    constructor() {
        this.pluginInstances = {};
    }

    registerInstance(el) {
        let instanceId =
            el.attr(`${reviewAddButtonV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${reviewAddButtonV1Semantics.mainWrapClass}`);
            el.attr(`${reviewAddButtonV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el)
    {
        let elements = $(`.${reviewAddButtonV1Semantics.mainWrapClass}[${
            reviewAddButtonV1Semantics
                .instanceIdAttribute
        }="${
            el.attr(reviewAddButtonV1Semantics
                .instanceIdAttribute)
        }"]`);
        elements.on(
            'click',
            `.${reviewAddButtonV1Semantics.elementClass}`,
            function(e) {
                e.preventDefault();
                let current = $(e.currentTarget);
                let popup = $(`.${reviewAddButtonV1Semantics.popupContainerClass}`);
                popup.show();
                popup.find(`.${reviewAddButtonV1Semantics.ownerModuleClass}`).val(el.data('owner-module'));
                popup.find(`.${reviewAddButtonV1Semantics.ownerTypeClass}`).val(el.data('owner-type'));
                popup.find(`.${reviewAddButtonV1Semantics.ownerIdClass}`).val(el.data('owner-id'));
                popup.find(`.${reviewAddButtonV1Semantics.errorMessageBlock}`).hide().addClass(reviewAddButtonV1Semantics.hideClass);
                popup.find(`.${reviewAddButtonV1Semantics.errorImageLimitMessageBLock}`).hide().addClass(reviewAddButtonV1Semantics.hideClass);
                popup.find(`.${reviewAddButtonV1Semantics.successMessageBlock}`).hide().addClass(reviewAddButtonV1Semantics.hideClass);
                popup.find(`.${reviewAddButtonV1Semantics.formClass}`).show().removeClass(reviewAddButtonV1Semantics.hideClass);
                popup.find(`.${reviewAddButtonV1Semantics.formClass}`).show().removeClass(reviewAddButtonV1Semantics.hideClass);
                //popup.find(`.${reviewAddButtonV1Semantics.raitStar}:eq(4)`).click();
        });

    }
}

export default ReviewAddButtonV1;