import $ from 'jquery';

/**
 * QuestionnaireEntityV1 widget configs
 */
const questionnaireEntityV1Semantics = {
    'mainWrapClass': 'questionnaire-entity-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'actionElement': '.questionnaire-entity-v1__btn',
    'stageContainerSelector': '.questionnaire-entity-v1__content',
    'stageSelector': '.questionnaire-entity-v1__stage',
    'stageIdAttribute': 'data-id',
    'activeStageClass': 'questionnaire-entity-v1__stage-active',
    'prevElementClass': 'questionnaire-entity-v1__prev',
    'formElement': 'questionnaire-entity-v1__form',
    'finalElement': 'questionnaire-entity-v1__final',

    // deps
    'checkedAttribute': 'data-is-checked',
    'inputElement': 'input',
};

/**
 * QuestionnaireEntityV1 initializer
 */
$(function () {
    QuestionnaireEntityV1.init();
});

class QuestionnaireEntityV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${questionnaireEntityV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.questionnaireEntityV1) {
            window.widgets.questionnaireEntityV1 = new QuestionnaireEntityV1();
        }

        /**
         * Checking other instances
         */
        $(`.${questionnaireEntityV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(questionnaireEntityV1Semantics.isInitializedAttribute) === 'true');
            if (!isInitialized) {
                window.widgets.questionnaireEntityV1.registerInstance($(el));
                window.widgets.questionnaireEntityV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${questionnaireEntityV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            // TODO[dependDetected]: slick plugin
            el.attr(`${questionnaireEntityV1Semantics.isInitializedAttribute}`, true);
            el.history = [];
            el.history.push(
                el.find(`.${questionnaireEntityV1Semantics.activeStageClass}`)
                    .attr(questionnaireEntityV1Semantics.stageIdAttribute)
            );
            window.widgets.questionnaireEntityV1.pluginInstances[instanceId] = el;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        let instanceId =
            el.attr(`${questionnaireEntityV1Semantics.instanceIdAttribute}`) || false;

        el.on('click', `.${questionnaireEntityV1Semantics.prevElementClass}`, function (event) {
            event.preventDefault();
            let e = event.currentTarget;
            if (history.length > 1) {
                let history = window.widgets.questionnaireEntityV1.pluginInstances[instanceId].history;
                history.pop();
                window.widgets.questionnaireEntityV1.showStage(el, history[history.length - 1]);
            }
        });
    }

    nextStageFromOptionItem(el, nextStageId) {
        let wrapper = el.closest(`.${questionnaireEntityV1Semantics.mainWrapClass}`) || false;
        let instanceId = wrapper.attr(`${questionnaireEntityV1Semantics.instanceIdAttribute}`) || false;
        let currentInstance = window.widgets?.questionnaireEntityV1?.pluginInstances[instanceId]
        if (currentInstance === undefined) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance questionnaire-entity with id [${instanceId}]`);
        }
        window.widgets?.questionnaireEntityV1?.pluginInstances[instanceId].history.push(nextStageId)
        window.widgets.questionnaireEntityV1.showStage(currentInstance, nextStageId);
    }

    showStage(instance, stageId) {
        let stageContainer = instance.find(questionnaireEntityV1Semantics.stageContainerSelector);
        stageContainer.find(`${questionnaireEntityV1Semantics.stageSelector}[${questionnaireEntityV1Semantics.stageIdAttribute}="${stageId}"]`)
            .addClass(questionnaireEntityV1Semantics.activeStageClass)
            .siblings()
            .removeClass(questionnaireEntityV1Semantics.activeStageClass);
    }

    finalStage(initiator) {
        let formElement = initiator.closest(`.${questionnaireEntityV1Semantics.formElement}`);
        formElement.hide();

        let finalElement = formElement.closest(`.${questionnaireEntityV1Semantics.mainWrapClass}`)
            .find(`.${questionnaireEntityV1Semantics.finalElement}`)
        let instanceId = finalElement.attr(`${questionnaireEntityV1Semantics.instanceIdAttribute}`) || false;
        let finalObject = window.widgets?.questionnaireFinalV1;
        let finalLink = this.calculateFinalLink(
            formElement
        );

        // прокидываем ссылку в финальный элемент
        if (finalObject && typeof finalObject.setFinalLink === 'function') {
            finalObject.setFinalLink(
                instanceId,
                finalLink
            );
        } else {
            // пишем лог
            console.warn('QuestionnaireFinal finalObject или метод calculated не найдены');
        }
        finalElement.show();
    }

    calculateFinalLink(formElement) {

        let params = [];
        let checkedElements = formElement.find(`[${questionnaireEntityV1Semantics.checkedAttribute}]`);
        checkedElements.each((index, el) => {
            let inputElement = $(el).find(questionnaireEntityV1Semantics.inputElement);
            if (inputElement.length && inputElement.val()) {
                let values = inputElement.val().split('&');
                params = params.concat(values);
            }
        })

        //убираем дубли
        let uniqueParams = $.grep(params, function (el, index) {
            return index == $.inArray(el, params);
        });

        // Формируем и возвращаем ссылку с параметрами
        return '/matrasy?' + uniqueParams.join('&') + '&sort=popular';
    }

    reInit(instanceId) {
        QuestionnaireEntityV1.init();
    }
}