import $ from 'jquery';

/**
 * QuestionnaireStageV1 widget configs
 */
const questionnaireStageV1Semantics = {
    'mainWrapClass': 'questionnaire-stage-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'actionElement': '.questionnaire-stage-v1__btn',
    'nextStageIdAttribute': 'data-next-stage-id',
    'finalAttribute': 'data-is-final',
};

/**
 * QuestionnaireStageV1 initializer
 */
$(function () {
    QuestionnaireStageV1.init();
});

class QuestionnaireStageV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${questionnaireStageV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.questionnaireStageV1) {
            window.widgets.questionnaireStageV1 = new QuestionnaireStageV1();
        }

        /**
         * Checking other instances
         */
        $(`.${questionnaireStageV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${questionnaireStageV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.questionnaireStageV1.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${questionnaireStageV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            // TODO[dependDetected]: slick plugin
            let object = el.find(`.${questionnaireStageV1Semantics.mainWrapClass}`);
            el.attr(`${questionnaireStageV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    validate(el) {
        let mainWrapper = el.closest(`.${questionnaireStageV1Semantics.mainWrapClass}`);
        let nextStageId = mainWrapper.attr(questionnaireStageV1Semantics.nextStageIdAttribute);
        if(nextStageId !== undefined) {
            window.widgets.questionnaireEntityV1.nextStageFromOptionItem(el, nextStageId)
        }
        else {
            if(mainWrapper.attr(questionnaireStageV1Semantics.finalAttribute) === 'true') {
                window.widgets.questionnaireEntityV1.finalStage(el)
            }
        }
    }

    reInit(instanceId) {
        QuestionnaireStageV1.init();
    }
}