import $ from 'jquery';
import {CartModule} from "../../services/cart/Cart";
import { ScreenMode } from "../../scripts/config";
import PerfectScrollbar from '../../scripts/perfect-scrollbar.min';
import { miniCartV1Semantics } from "../mini-cart-v1/mini-cart-v1";

/**
 * [@mvp] надо перенести часть логики в mini-cart-data-item-v1
 * пока здесь оставляю время поджимает.
 *
 * MiniCartDataV1 widget configs
 */
const miniCartDataV1Semantics = {
    'mainWrapClass': 'mini-cart-data-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'initializeContainerValueAttribute': 'isInitialized',
    'totalPriceOldElement': 'mini-cart-data-v1__price-old',
    'totalPriceElement': 'mini-cart-data-v1__price',
    'cartScrollbar': 'mini-cart-data-v1__scroll-inner',
    'cartScrollbarContainer': 'mini-cart-data-v1__js-scrollable',
    'maxCount': 99,
    'minCount': 1,
    // время ожидания перед отправкой запроса в мс.
    'debounceTime': 500,
    'commonNotScrollClass': 'mini-cart-data-v1__no-scroll',
    'closeBtn': 'mini-cart-data-v1__cart-close-btn',

    // выносить этот блок в mini-cart-data-item-v1
    'cartCounterElement': 'mini-cart-data-item-v1__cart-counter',
    'cartCounterDecreaseElement': 'mini-cart-data-item-v1__decrease-counter',
    'cartCounterIncreaseElement': 'mini-cart-data-item-v1__increase-counter',
    'cartCounterRemoveElement': 'mini-cart-data-item-v1__remove-item',
    'cartElementContainer': 'mini-cart-data-item-v1',
    'cartProductPrice': 'data-price',
    'cartProductPriceOld': 'data-price-old',
    'cartItemPrice': 'mini-cart-data-item-v1__total-price',
    'cartItemPriceOld': 'mini-cart-data-item-v1__total-price-old',

    'productTypeId': 'data-type-id',
    'productId': 'data-product-id',
    'productVirtualModifierId': 'data-product-modifier-id',
};

export class MiniCartDataV1 {

    constructor() {
        this.pluginInstances = {};
        // для дебаунсинга
        this.debounceTimer = null;
    }

    static init(dataUrl, containerElement, callback) {
        if (containerElement.length === 0) {
            return false;
        }

        // Проверка, была ли уже инициализация
        if (containerElement.data(miniCartDataV1Semantics.initializeContainerValueAttribute)) {
            return false;
        }

        $.ajax({
            type: 'GET',
            url: dataUrl,
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content")},
            dataType: 'html',
            success: function (data) {
                // Убираем лоадер и показываем загруженные данные
                containerElement.html(data);

                let mainWrapClass = containerElement.find(`.${miniCartDataV1Semantics.mainWrapClass}`);

                if (!mainWrapClass || mainWrapClass.length === 0) {
                    return false;
                }

                if (!window || !window.widgets || !window.widgets.miniCartDataV1) {
                    window.widgets.miniCartDataV1 = new MiniCartDataV1();
                }

                let $scrollInner = containerElement.find(`.${miniCartDataV1Semantics.cartScrollbar}`);
                // Инициализируем кастомный скролл после рендера
                window.widgets.miniCartDataV1.initCustomScroll($scrollInner);
                // Обновляем адаптивное отображение
                window.widgets.miniCartDataV1.updatePopupLayout(containerElement);

                callback();

                /**
                 * Проверяем другие инстансы
                 */
                mainWrapClass.each((index, el) => {
                    let isInitialized =
                        ($(el).attr(`${miniCartDataV1Semantics.isInitializedAttribute}`) === 'true');
                    if (!isInitialized) {
                        window.widgets.miniCartDataV1.registerInstance($(el));
                        containerElement.data(miniCartDataV1Semantics.initializeContainerValueAttribute, true);
                        window.widgets.miniCartDataV1.registerListeners($(el));
                    }
                });

                // Устанавливаем флаг, что инициализация завершена
                containerElement.data(miniCartDataV1Semantics.initializeContainerValueAttribute, true);

                $(window).on('resize', () => {
                        window.widgets.miniCartDataV1.updatePopupLayout(containerElement);
                    }
                );
            },
            error: function () {
                // В случае ошибки запроса показываем сообщение
                // containerElement.html('<div class="error-message">Данные не могут быть загружены, попробуйте позже</div>');
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${miniCartDataV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${miniCartDataV1Semantics.mainWrapClass}`);
            el.attr(`${miniCartDataV1Semantics.isInitializedAttribute}`, true);
            window.widgets.miniCartDataV1.pluginInstances[instanceId] = object;
        } catch (e) {
            throw e;
        }
    }

    registerListeners(el) {
        // Событие для увеличения количества товаров
        el.on('click', `.${miniCartDataV1Semantics.cartCounterIncreaseElement}`, (event) => {
            let row = $(event.currentTarget).closest(`.${miniCartDataV1Semantics.cartElementContainer}`);
            const $input = row.find(`.${miniCartDataV1Semantics.cartCounterElement}`)
            ;
            if (parseInt($input.val()) >= miniCartDataV1Semantics.maxCount) {
                $input.val(miniCartDataV1Semantics.maxCount);
            } else {
                $input.val(parseInt($input.val()) + 1);
            }
            this.reCalculate(row);
            this.debounceUpdateCart(row);
        });

        // Событие для уменьшения количества товаров
        el.on('click', `.${miniCartDataV1Semantics.cartCounterDecreaseElement}`, (event) => {
            let row = $(event.currentTarget).closest(`.${miniCartDataV1Semantics.cartElementContainer}`);
            const $input = row.find(`.${miniCartDataV1Semantics.cartCounterElement}`)
            ;
            if (parseInt($input.val()) <= miniCartDataV1Semantics.minCount) {
                $input.val(miniCartDataV1Semantics.minCount);
            } else {
                $input.val(parseInt($input.val()) - 1);
            }
            this.reCalculate(row);
            this.debounceUpdateCart(row);
        });

        // Событие для изменения количества товаров напрямую в поле ввода
        el.on('input', `.${miniCartDataV1Semantics.cartCounterElement}`, (event) => {
            let row = $(event.currentTarget).closest(`.${miniCartDataV1Semantics.cartElementContainer}`);
            const $input = $(event.currentTarget);

            if($input.val().trim() === "") {
                return;
            }
            let value = parseInt($input.val(), 10);
            $input.val(value);
            if (isNaN(value) || !Number.isFinite(value)) {
                $input.val(miniCartDataV1Semantics.minCount);
            }
            if (value < miniCartDataV1Semantics.minCount) {
                $input.val(miniCartDataV1Semantics.minCount);
            }
            if (value > miniCartDataV1Semantics.maxCount) {
                $input.val(miniCartDataV1Semantics.maxCount);
            }
            this.reCalculate(row);
            this.debounceUpdateCart(row);

        });

        // remove item
        el.on('click', `.${miniCartDataV1Semantics.cartCounterRemoveElement}`, (event) => {
            let row = $(event.currentTarget).closest(`.${miniCartDataV1Semantics.cartElementContainer}`);
            row.addClass('_remove-animation');
            row.on('animationend', function () {
                // Удаляем элемент из DOM после завершения анимации
                $(this).remove();
            });

            const $input = row.find(`.${miniCartDataV1Semantics.cartCounterElement}`)
            $input.val(0);
            this.reCalculate(row);
            this.debounceUpdateCart(row);
        });

        // закрытие корзины
        el.on('click', `.${miniCartDataV1Semantics.closeBtn}`, (event) => {
            $(event.currentTarget).closest(`.${miniCartDataV1Semantics.mainWrapClass}`).hide();
            this.enableScroll();
        })
    }

    // Дебаунсинг: отправляем только последний запрос
    debounceUpdateCart(row) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(
            () => {
                this.updateCartQuantity(row);
            },
            miniCartDataV1Semantics.debounceTime
        );  // Задержка в 500мс перед отправкой последнего запроса
    }

    // обновление кол-ва в корзине
    updateCartQuantity(row) {
        // productTypeId, id, count, virtualModifierId
        // шлем на сервер обработку& надо реализовать
        let productTypeId = row.attr(miniCartDataV1Semantics.productTypeId);
        let id = row.attr(miniCartDataV1Semantics.productId);
        let count = parseInt(
            row.find(`.${miniCartDataV1Semantics.cartCounterElement}`).val()
        );
        let virtualModifierId = row.attr(miniCartDataV1Semantics.productVirtualModifierId);

        CartModule.cart.addOrChange(
            productTypeId,
            id,
            count,
            virtualModifierId
        );
    }

    // пересчет цен и кол-ва в товаров в корзине
    reCalculate(row) {
        let container = row.closest(`.${miniCartDataV1Semantics.mainWrapClass}`);
        // Инициализируем переменные для хранения общей суммы
        let totalSalePrice = 0;
        let totalOldPrice = 0;
        const formatter = new Intl.NumberFormat('ru-RU');
        container.find(`.${miniCartDataV1Semantics.cartElementContainer}`).each((index, rowElement) => {
            let $row = $(rowElement);

            // Получаем количество товара
            let quantity = parseInt($row.find(`.${miniCartDataV1Semantics.cartCounterElement}`).val());

            // Получаем цену товара со скидкой и старую цену
            let salePrice = parseFloat($row.attr(miniCartDataV1Semantics.cartProductPrice));
            let oldPrice = parseFloat($row.attr(miniCartDataV1Semantics.cartProductPriceOld));

            // Пересчитываем сумму для текущего товара
            let totalRowSalePrice = salePrice * quantity;
            let totalRowOldPrice = oldPrice * quantity;


            // Обновляем цены в HTML для текущего элемента
            $row.find(`.${miniCartDataV1Semantics.cartItemPrice}`).text(`${formatter.format(totalRowSalePrice)} руб.`);
            $row.find(`.${miniCartDataV1Semantics.cartItemPriceOld}`).text(`${formatter.format(totalRowOldPrice)} руб.`);

            // Добавляем суммы к общим значениям
            totalSalePrice += totalRowSalePrice;
            totalOldPrice += totalRowOldPrice;
        });

        // Обновляем общие значения для корзины
        container.find(`.${miniCartDataV1Semantics.totalPriceElement}`).text(`${formatter.format(totalSalePrice)} руб.`);
        container.find(`.${miniCartDataV1Semantics.totalPriceOldElement}`).text(`${formatter.format(totalOldPrice)} руб.`);

    }

    // Инициализация скролла
    initCustomScroll($scrollInner) {
        let scrollableContainer = $scrollInner.find(`.${miniCartDataV1Semantics.cartScrollbarContainer}`);
        // Проверка наличия элементов для скролла
        // || $scrollInner.height() >= scrollableContainer[0].scrollHeight
        if (scrollableContainer.length < 1) {
            return;
        }
        // Инициализация PerfectScrollbar
        this.scrollbar = new PerfectScrollbar($scrollInner[0], {
            wheelPropagation: false,
            suppressScrollX: true,
        });
    }

    destroyScroll() {
        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    reinitCustomScroll($scrollInner) {
        // Удаляем существующий скролл
        this.destroyScroll();
        // Инициализируем заново
        this.initCustomScroll($scrollInner);
    }
    
    // Обновление скролла
    updateScroll() {
        if (this.scrollbar) {
            this.scrollbar.update();
        }
    }

    // ререндер окна корзины пока моб версия/обычная версия
    updatePopupLayout(containerElement) {
        const screenMode = ScreenMode.getWindowMode();
        let $cartPopup = null;
        switch (screenMode) {
            case 'mobile':
                $cartPopup = containerElement.children().first();
                if ($cartPopup.length && !$cartPopup.hasClass(miniCartV1Semantics.mobileClass)) {
                    $('body').append($cartPopup);
                    $cartPopup.addClass(miniCartV1Semantics.mobileClass);
                    this.reinitCustomScroll($cartPopup.find(`.${miniCartDataV1Semantics.cartScrollbar}`));
                    this.disableScroll();
                }
                //пересчитать высоту окна корзины
                this.adjustScrollHeight();
                break;
            default:
                $cartPopup = $('body').find(`.${miniCartDataV1Semantics.mainWrapClass}`);
                if ($cartPopup.hasClass(miniCartV1Semantics.mobileClass)) {
                    $cartPopup.removeClass(miniCartV1Semantics.mobileClass);
                    containerElement.prepend($cartPopup);
                    this.enableScroll();
                    this.reinitCustomScroll($cartPopup.find(`.${miniCartDataV1Semantics.cartScrollbar}`));
                }
                break;

        }
    }

    disableScroll() {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        $('body').css({
            position: 'fixed',
            top: `-${scrollTop}px`,
            width: '100%',
        }).addClass(
            miniCartDataV1Semantics.commonNotScrollClass
        );
    }

    enableScroll() {
        const scrollTop = Math.abs(parseInt($('body').css('top'), 10));
        $('body').css({
            position: '',
            top: '',
            width: '',
        }).removeClass(
            miniCartDataV1Semantics.commonNotScrollClass
        );
        window.scrollTo(0, scrollTop);
    }

    // [@mvp] метод для выравнивая окна прокрутки корзины
    adjustScrollHeight() {
        const screenMode = ScreenMode.getWindowMode();

        if (screenMode === 'mobile') {
            // Высота окна
            const windowHeight = window.innerHeight;

            // Получаем высоту фиксированных блоков
            // Кнопка "Назад"
            const backButtonHeight = $('.mini-cart-data-v1__cart-close-wrapper').outerHeight(true);
            // Футер с кнопками и ценой
            const footerHeight = $('.mini-cart-data-v1__footer').outerHeight(true);
            // Нижнее меню
            const mobileMenuHeight = $('.sticky-footer-v1').outerHeight(true);

            // Расчет высоты для блока с PerfectScrollbar
            const scrollableHeight = windowHeight - backButtonHeight - footerHeight - mobileMenuHeight;

            // Устанавливаем высоту для блока с прокруткой
            $(`.${miniCartDataV1Semantics.cartScrollbar}`).css({
                height: scrollableHeight + 'px'
            });
            // Обновляем скролл после изменения высоты
            this.updateScroll();
        }
    }
}