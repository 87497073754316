import $ from 'jquery';

const reviewModalV1Semantics = {
    mainWrapClass: 'review-modal-v1',
    popupContainerClass: 'review-modal-v1_popup',
    isInitializedAttribute: 'data-is-initialized',
    instanceIdAttribute: 'data-instance-id',
    submitClass: 'review-modal-v1__submit',
    activeElementClass: 'review-modal-v1__item-active',
    successClass: 'review-modal-v1__success',
    errorFieldClass: 'review-modal-v1__error',
    closeModal: 'review-modal-v1__close',
    errorClass: 'js-error-popup',
    hideClass: 'review-modal-v1__hide',
    urlAddReview: '/review/add',
    rate: 'review-modal-v1__rate-field',
    youtube: 'review-modal-v1__youtube-field',
    text: 'review-modal-v1__text-field',
    fio: 'review-modal-v1__fio-field',


}

$(function () {
    let roots = $(`.${reviewModalV1Semantics.mainWrapClass}`);
    if (roots.length === 0) {
        return false;
    }
    let reviewModalV1 = new ReviewModalV1();
    /**
     * Checking other instances
     */
    roots.each((index, el) => {
        let isInitialized =
            ($(el).attr(`${reviewModalV1Semantics.isInitializedAttribute}`) === 'true');

        if (!isInitialized) {
            reviewModalV1.registerInstance($(el));
            reviewModalV1.registerListeners($(el));
        }
    });
})

class ReviewModalV1 {

    constructor() {
        this.pluginInstances = {};
    }

    registerInstance(el) {
        let instanceId =
            el.attr(`${reviewModalV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${reviewModalV1Semantics.mainWrapClass}`);
            el.attr(`${reviewModalV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el)
    {
        let instanceID = el.attr(reviewModalV1Semantics.instanceIdAttribute);
        let instanceObj = $(`#${instanceID}`);
        let elements = $(`.${reviewModalV1Semantics.mainWrapClass}[${
            reviewModalV1Semantics
                .instanceIdAttribute
        }="${
            el.attr(reviewModalV1Semantics
                .instanceIdAttribute)
        }"]`);

        elements.on(
            'click',
            `.${reviewModalV1Semantics.submitClass}`,
            function(e) {
                e.preventDefault();
                let current = $(e.currentTarget);
                let data = instanceObj.serialize();
                $.ajax({
                    type: 'POST',
                    headers: { 'X-CSRF-Token' : $('meta[name="csrf-token"]').attr("content") },
                    url: reviewModalV1Semantics.urlAddReview,
                    dataType: 'JSON',
                    data: data,
                    success:function(response){
                        switch (response.status) {
                            case true:
                                instanceObj.slideUp();
                                instanceObj.siblings(`.${reviewModalV1Semantics.successClass}`).removeClass(reviewModalV1Semantics.hideClass).slideDown();
                            break;
                            case false:
                                let error = response.data;
                                if(error.youtube === false) {
                                    instanceObj.find(`.${reviewModalV1Semantics.youtube}`).addClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }
                                else{
                                    instanceObj.find(`.${reviewModalV1Semantics.youtube}`).removeClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }

                                if(!error.text) {
                                    instanceObj.find(`.${reviewModalV1Semantics.text}`).addClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }
                                else{
                                    instanceObj.find(`.${reviewModalV1Semantics.text}`).removeClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }

                                if(!error.fio) {
                                    instanceObj.find(`.${reviewModalV1Semantics.fio}`).addClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }
                                else{
                                    instanceObj.find(`.${reviewModalV1Semantics.fio}`).removeClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }

                                if(!error.rate) {
                                    instanceObj.find(`.${reviewModalV1Semantics.rate}`).addClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }
                                else{
                                    instanceObj.find(`.${reviewModalV1Semantics.rate}`).removeClass(
                                        reviewModalV1Semantics.errorFieldClass
                                    );
                                }
                                //instanceObj.slideUp();
                                //instanceObj.siblings(`.${reviewModalV1Semantics.errorClass}`).removeClass(reviewModalV1Semantics.hideClass).slideDown();
                            break;
                        }
                    }
                });
            });

        elements.on('click',`.${reviewModalV1Semantics.closeModal}`,function(e){
            e.preventDefault();
            let current = $(e.currentTarget);
            let popup = $(`.${reviewModalV1Semantics.popupContainerClass}`);
            popup.hide();
        });
    }
}

export default ReviewModalV1;