import $ from 'jquery';
import { MiniCartDataV1 } from '../mini-cart-data-v1/mini-cart-data-v1';
/**
 * MiniCartV1 widget configs
 */
const miniCartV1Semantics = {
    'mainWrapClass': 'mini-cart-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'actionElement': 'mini-cart-v1__init',
    'dataRequestUrlAttribute': 'data-request-url',
    'contentWrapClass': 'mini-cart-v1__content-container',
    'mobileClass': 'mini-cart-v1__mobile-view'
};


$(function () {
    MiniCartV1.init();
})

class MiniCartV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${miniCartV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.miniCartV1) {
            window.widgets.miniCartV1 = new MiniCartV1();
        }

        /**
         * Checking other instances
         */
        $(`.${miniCartV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${miniCartV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.miniCartV1.registerInstance($(el));
                window.widgets.miniCartV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${miniCartV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${miniCartV1Semantics.mainWrapClass}`);
            el.attr(`${miniCartV1Semantics.isInitializedAttribute}`, true);
            window.widgets.miniCartV1.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        // Флаг для отслеживания, была ли выполнена инициализация
        // при первом клике, инициализируем мини корзину только 1 раз.
        // при повторных кликах мы ее просто тоглим.
        let isContentInitialized = false;

        el.on('click', `.${miniCartV1Semantics.actionElement}`, (event) => {
            event.preventDefault();
            let contentWrap = el.find(`.${miniCartV1Semantics.contentWrapClass}`);
            // Проверяем, была ли уже выполнена инициализация
            if (!isContentInitialized) {

                MiniCartDataV1.init(
                    el.attr(`${miniCartV1Semantics.dataRequestUrlAttribute}`),
                    contentWrap,
                   function() {
                        this.togglePopup(contentWrap)
                    }.bind(this)
                );
                // Устанавливаем флаг после инициализации
                isContentInitialized = true;
            } else {
                // Тоглим видимость контента, если инициализация уже была выполнена
                this.togglePopup(contentWrap);
            }
        });

        // Закрытие мини-корзины при клике вне ее
        $(document).on('click', (event) => {
            // Проверяем, был ли клик за пределами всплывающего окна
            if (!$(event.target).closest(`.${miniCartV1Semantics.mainWrapClass}`).length) {
                let contentWrap = el.find(`.${miniCartV1Semantics.contentWrapClass}`);
                // Скрываем мини-корзину, если она открыта
                if (isContentInitialized && contentWrap.is(':visible')) {
                    contentWrap.hide();
                }
            }
        });
    }

    togglePopup(contentWrap) {
        contentWrap.toggle();
        let isVisible = contentWrap.is(':visible');
        console.log("visible", isVisible);
        if (isVisible) {
            contentWrap.children().first().show();
        } else {
            contentWrap.children().first().hide();
        }
    }
}

export  {
    MiniCartV1,
    miniCartV1Semantics,
}