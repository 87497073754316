// Services, общие скрипты и виджеты
require('./../services/prototype/Common');
require('./../services/validator/ValidatorInit');
require('./../enums/MechanicTypeEnum.js');
require('./../services/csrf/Csrf.js');
require('./../services/changer/Changer.js');
require('./../services/cart/Cart.js');
require('./../widgets/marketing-event-v1/marketing-event-v1.js');
require('./../widgets/seo-text-block-v1/seo-text-block-v1.js');
require('./../widgets/add-to-cart-v1/add-to-cart-v1.js');
require('./../widgets/add-to-wishlist-v1/add-to-wishlist-v1.js');
require('./../widgets/add-to-compare-v1/add-to-compare-v1.js');
require('./../widgets/text-page-delivery-v1/text-page-delivery-v1.js');
require('./../widgets/text-page-warranty-v1/text-page-warranty-v1.js');
require('./../widgets/review-add-button-v1/review-add-button-v1.js');
require('./../widgets/rating-star-vote-v1/rating-star-vote-v1.js');
require('./../widgets/tooltip-v1/tooltip-v1.js');
require('./../widgets/extra-data-v1/extra-data-v1.js');
require('./../widgets/select-v2/select-v2.js');
require('./../widgets/cta-v1/cta-v1.js');
require('./../widgets/cta-v2/cta-v2.js');

// Скрипты прошлого проекта
// TODO[needImpl]: требуют дополнительных
// переработок
require('./../scripts/form.js');
require('./../scripts/cssVars.js');
require('./../scripts/inputmask.js');
require('./../scripts/helpers.js');
require('./../scripts/initSmoothScroll.js');
require('./../scripts/initSearch.js');
require('./../scripts/initPopup.js');

// modal windows
require('./../widgets/modal-window-add-to-cart-v1/modal-window-add-to-cart-v1');
require('./../widgets/modal-window-order-call-v1/modal-window-order-call-v1');
require('./../widgets/review-modal-v1/review-modal-v1.js');
require('./../widgets/region-select-popup-v1/region-select-popup-v1.js');
require('./../widgets/modal-select-location-window-v1/modal-select-location-window-v1.js');
