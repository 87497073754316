import $ from 'jquery';
import 'slick';
import { 
    size,
    slickCommonOptions,
} from "../../scripts/config";

/**
 * TODO[needImplByPriority:1]: раскидать по виджетам,
 * учти, что связаны через открытие popup-окна (ищи: js-interior-open-popup)
 * Должны быть 4 виджета: interior-v1/interior-images-v1/interior-products-v1/modal-window-*
 * и в окно-виджет должны подгружаться другие виджеты (interior-images-v1/interior-products-v1)
 */

/**
 * InteriorV1 widget configs
 */
const interiorV1Semantics = {
    'mainWrapClass': 'interior-v1__wrap',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'interior-v1__prev',
    'controlNextClass': 'interior-v1__next',
    'sliderElementClass': 'interior-v1__list-place',
};
/**
 * InteriorProductsV1 widget configs
 */
const interiorProductsV1Semantics = {
    'mainWrapClass': 'interior-products-v1__wrap',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'interior-products-v1__prev',
    'controlNextClass': 'interior-products-v1__next',
    'sliderElementClass': 'interior-products-v1__list-place',
};

/**
 * InteriorImagesV1 widget configs
 */
const interiorImagesV1Semantics = {
    'mainWrapClass': 'interior-images-v1__wrap',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'interior-images-v1__prev',
    'controlNextClass': 'interior-images-v1__next',
    'sliderElementClass': 'interior-images-v1__list-place',
};


/**
 * InteriorV1 initializer
 */
$(function () {

    if ($(`.${interiorV1Semantics.mainWrapClass}`).length === 0) {
        return false;
    }

    let interiorV1 = new InteriorV1();

    /**
     * Checking other instances
     */
    $(`.${interiorV1Semantics.mainWrapClass}`).each((index, el) => {
        let isInitialized =
            ($(el).attr(`${interiorV1Semantics.isInitializedAttribute}`) === 'true');
        if (!isInitialized) {
            interiorV1.registerInstance($(el));
        }
    });

    $('.js-interior-open-popup').magnificPopup({
        type: 'ajax',
        closeBtnInside: true,
        closeMarkup: `<div class="interior-popup-v1__close js-popup-close">
            <svg class="interior-popup-v1__close-icon" width="16" height="16">
                <use xlink:href="${iconSVG}#close-icon"></use>
            </svg>
        </div>`,
        overflowY: 'scroll',
        fixedContentPos: true,
        preloader: false,
        ajax: {
            settings: {cache: false},
            cursor: 'mfp-ajax-cur',
            tError: '<a href="%url%">The content</a> could not be loaded.'
        },
        callbacks: {
            ajaxContentAdded: function () {
                let interiorImagesV1 = new InteriorImagesV1();

                /**
                 * Checking other instances
                 */
                $(`.${interiorImagesV1Semantics.mainWrapClass}`).each((index, el) => {
                    let isInitialized =
                        ($(el).attr(`${interiorImagesV1Semantics.isInitializedAttribute}`) === 'true');
                    if (!isInitialized) {
                        interiorImagesV1.registerInstance($(el));
                    }
                });

                let interiorProductsV1 = new InteriorProductsV1();

                /**
                 * Checking other instances
                 */
                $(`.${interiorProductsV1Semantics.mainWrapClass}`).each((index, el) => {
                    let isInitialized =
                        ($(el).attr(`${interiorProductsV1Semantics.isInitializedAttribute}`) === 'true');
                    if (!isInitialized) {
                        interiorProductsV1.registerInstance($(el));
                    }
                });

                $(window).trigger('resize'); // Hotfix for a wrong initialized first element

                /**
                 * Init gift tooltips
                 */
                $('.interior-popup-v1').find('.js-gift-tooltip').on('click mouseenter', function (e) {
                    e.preventDefault();
                    initGiftTooltip($(this));
                    return false;
                });
            }
        }
    });
});

class InteriorV1 {
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${interiorV1Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions =
            el.data(
                `${interiorV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: false,
                variableWidth: true,
                mobileFirst: true,
                swipeToSlide: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: size.smDesktop - 1,
                        settings: {
                            variableWidth: false,
                            slidesToShow: 3,
                            arrows: true,
                            mobileFirst: true,
                            swipeToSlide: true,
                            prevArrow: `.${interiorV1Semantics.controlPrevClass}`,
                            nextArrow: `.${interiorV1Semantics.controlNextClass}`,
                        }
                    },
                    {
                        breakpoint: 1130,
                        settings: {
                            variableWidth: false,
                            slidesToShow: 4,
                            arrows: true,
                            mobileFirst: true,
                            swipeToSlide: true,
                            prevArrow: `.${interiorV1Semantics.controlPrevClass}`,
                            nextArrow: `.${interiorV1Semantics.controlNextClass}`,
                        }
                    },
                    {
                        breakpoint: 1420,
                        settings: {
                            variableWidth: false,
                            slidesToShow: 4,
                            arrows: true,
                            mobileFirst: true,
                            swipeToSlide: true,
                            prevArrow: `.${interiorV1Semantics.controlPrevClass}`,
                            nextArrow: `.${interiorV1Semantics.controlNextClass}`,
                        }
                    },
                ]
            }
        );

        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${interiorV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);

            // слик какогото хрена убирает стрелки нет времени разбираться костыль
            // $(`.${interiorV1Semantics.controlPrevClass}`).removeClass('slick-hidden');
            // $(`.${interiorV1Semantics.controlNextClass}`).removeClass('slick-hidden');

            el.attr(`${interiorV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = slick;
        } catch (e) {
            el.hide();
            throw e;
        }
    }
}

class InteriorProductsV1 {
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${interiorProductsV1Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions =
            el.data(
                `${interiorProductsV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: false,
                variableWidth: true,
                mobileFirst: true,
                swipeToSlide: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 475,
                        settings: {
                            variableWidth: true,
                            slidesToShow: 1,
                            arrows: true,
                            mobileFirst: true,
                            swipeToSlide: true,
                            prevArrow: `.${interiorProductsV1Semantics.controlPrevClass}`,
                            nextArrow: `.${interiorProductsV1Semantics.controlNextClass}`,
                        }
                    },
                ]
            }
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${interiorProductsV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);
            el.attr(`${interiorProductsV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = slick;
        } catch (e) {
            el.hide();
            throw e;
        }
    }
}

class InteriorImagesV1 {
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${interiorImagesV1Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions =
            el.data(
                `${interiorImagesV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                dots: true,
                arrows: false,
                variableWidth: false,
                mobileFirst: true,
                swipeToSlide: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 560,
                        settings: {
                            dots: false,
                            variableWidth: true,
                            slidesToShow: 1,
                            arrows: true,
                            mobileFirst: true,
                            swipeToSlide: true,
                            prevArrow: `.${interiorImagesV1Semantics.controlPrevClass}`,
                            nextArrow: `.${interiorImagesV1Semantics.controlNextClass}`,
                        }
                    },
                ]
            }
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${interiorImagesV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);
            el.attr(`${interiorImagesV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = slick;
        } catch (e) {
            el.hide();
            throw e;
        }
    }
}