import $ from 'jquery';
import Cookies from 'js-cookie';
import CsrfToken from '../csrf/Csrf';
import {ProductTypeEnum} from '../../enums/ProductTypeEnum';

const WishlistModule = {};

WishlistModule.List = class
{
    isLazyInit = false;

    /**
     * @var {WishlistModule.ListProductSet}
     */
    productSet;

    /**
     * @var {WishlistModule.CounterTracker}
     */
    counterTracker;

    /**
     * @param {bool} isLazyInit 
     */
    constructor(isLazyInit = false) {
        let self = this;
        self.isLazyInit = isLazyInit;
        if (self.isLazyInit === false) {
            self.initComponent();
        }
    }

    initComponent() {

        let self = this;
        self.productSet = new WishlistModule.ListProductSet();
        self.counterTracker = new WishlistModule.CounterTracker(
            self
        );

        let rawCart = Cookies.get('wishList') ? JSON.parse(Cookies.get('wishList')) : [];
        self.fillElements(rawCart);

    }

    updateCounterTracker() {
        this.counterTracker.update();
    }

    getProductSet() {
        return this.productSet;
    }

    /**
     * @param {array} rawElements 
     */
    fillElements(rawElements) {

        let self = this;

        // clear old values
        self.productSet.products = [];

        for (let key in rawElements) {
            switch (rawElements[key].productTypeId) {
                case ProductTypeEnum.card:
                    self.productSet.products.push(
                        new WishlistModule.CardCartProduct(
                            rawElements[key]
                        )
                    );
                break;
                case ProductTypeEnum.realModifier:
                    self.productSet.products.push(
                        new WishlistModule.RealModifierCartProduct(
                            rawElements[key]
                        )
                    );
                break;
            }
        }
    }

    /**
     * @param {int} productTypeId 
     * @param {int} id
     * @param {int|null} virtualModifierId 
     */
    addOrChange(
        productTypeId,
        id,
        virtualModifierId = null,
        callback= null,
    )
    {
        let self = this;
        let data = {
            'productTypeId': productTypeId,
            'id': id,
            'virtualModifierId': virtualModifierId,
        };
        data[CsrfToken.name] = CsrfToken.value;

        $.post({
            url: '/wishlist/add',
            data: data,
            success: function(rawElements) {
                self.fillElements(rawElements);
                self.counterTracker.update();
                if (typeof callback === 'function') {
                    callback();
                }
            },
            error: function(xhr, status, error) {
                console.error("Error occurred: " + status + ", " + error);
            }
        })
    }

    /**
     * @return {int}
     */
    getSummaryCount() {
        return this.productSet.getSummaryCount();
    }

}

WishlistModule.CounterTracker = class {

    /**
     * @var {WishlistModule.List}
     */
    list;

    trackerSelector = [
        {
            counterSelector: '.site-header__indicators-link.main-favorite .site-header__indicators-count span',
            wrapSelector: '.site-header__indicators-link.main-favorite .site-header__indicators-count'
        },
        {
            counterSelector: '.sticky-footer-v1__wishlist .site-header__indicators-count span',
            wrapSelector: '.sticky-footer-v1__wishlist .site-header__indicators-count'
        },
        {
            counterSelector: '.menu-bar__favorite-item .menu-bar__indicators-count span',
            wrapSelector: '.menu-bar__favorite-item .menu-bar__indicators-count'
        }

    ];

    constructor(list) {
        let self = this;
        self.list = list;
    }

    update() {
        let self = this;
        for (let key in self.trackerSelector) {
            let counterSelector = self.trackerSelector[key].counterSelector;
            let wrapSelector = self.trackerSelector[key].wrapSelector;
            if (self.list.getSummaryCount() > 0) {
                $(wrapSelector).removeClass('hidden');
                $(counterSelector).empty().html(self.list.getSummaryCount());
            } else {
                $(wrapSelector).addClass('hidden');
            }

        }

    }

}

WishlistModule.ListProductSet = class {

    /**
     * @var {(WishlistModule.CardCartProduct|WishlistModule.RealModifierCartProduct)[]}
     */
    products = [];
    
    /**
     * @return {int}
     */
    getSummaryCount() {
        return this.products.length;
    }

}

WishlistModule.BaseCartProduct = class {
    id;
    productTypeId;
}

WishlistModule.CardCartProduct = class {
    productTypeId = ProductTypeEnum.card;
    
    constructor(cartElement) {
        let self = this;
        self.id = cartElement.id;
    }
}

WishlistModule.RealModifierCartProduct = class {

    productTypeId = ProductTypeEnum.realModifier;

    /**
     * @var {WishlistModule.VirtualModifierCartProduct|null}
     */
    virtualModifier = null;

    constructor(cartElement) {
        let self = this;
        self.id = cartElement.id;
        if (cartElement.virtualModifierId) {
            self.virtualModifier = new WishlistModule.VirtualModifierCartProduct({
                id: cartElement.virtualModifierId
            });
        }
    }
}

WishlistModule.VirtualModifierCartProduct = class {
    productTypeId = ProductTypeEnum.virtualModifier;

    constructor(cartElement) {
        let self = this;
        self.id = cartElement.id;
    }
}

WishlistModule.list = new WishlistModule.List();

export {
    WishlistModule
};