// TMP variables
window.widgets = {};
window.attachFiles = {};

require('./../../groups/common');
require('./../../groups/services');
require('./../../groups/header');

// widgets
require('./../../widgets/slider-v5/slider-v5');
require('./../../widgets/advantages-v1/advantages-v1');
// questionnaire
require('./../../widgets/questionnaire-v1/questionnaire-v1');
require('./../../widgets/questionnaire-entity-v1/questionnaire-entity-v1');
require('./../../widgets/questionnaire-entity-v1/questionnaire-entity-v1');
require('./../../widgets/questionnaire-option-item-v1/questionnaire-option-item-v1');
require('./../../widgets/questionnaire-stage-v1/questionnaire-stage-v1');
require('./../../widgets/questionnaire-stage-v2/questionnaire-stage-v2');
require('./../../widgets/questionnaire-final-v1/questionnaire-final-v1');
// \questionnaire
require('./../../widgets/gallery-v3/gallery-v3');
require('./../../widgets/product-gallery-v1/product-gallery-v1');
require('./../../widgets/interior-v1/interior-v1');
require('./../../widgets/image-carousel-v1/image-carousel-v1');
require('./../../widgets/review-slider-v1/review-slider-v1');
require('./../../widgets/showroom-v2/showroom-v2');
require('./../../widgets/subscription-request-v1/subscription-request-v1');

// modal windows