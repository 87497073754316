import $ from 'jquery';

/**
 * QuestionnaireStageV2 widget configs
 */
const questionnaireStageV2Semantics = {
    'mainWrapClass': 'questionnaire-stage-v2',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'actionElement': '.questionnaire-stage-v2__btn',
    'nextStageIdAttribute': 'data-next-stage-id',
    'groupIdentityElement': 'questionnaire-stage-v2__column',
    'optionElement': 'questionnaire-stage-v2__element',
    'dataCheckAttribute': 'data-is-checked',
    'finalAttribute': 'data-is-final',
};

/**
 * QuestionnaireStageV2 initializer
 */
$(function () {
    QuestionnaireStageV2.init();
});

class QuestionnaireStageV2 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${questionnaireStageV2Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.questionnaireStageV2) {
            window.widgets.questionnaireStageV2 = new QuestionnaireStageV2();
        }

        /**
         * Checking other instances
         */
        $(`.${questionnaireStageV2Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${questionnaireStageV2Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.questionnaireStageV2.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${questionnaireStageV2Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            // TODO[dependDetected]: slick plugin
            let object = el.find(`.${questionnaireStageV2Semantics.mainWrapClass}`);
            el.attr(`${questionnaireStageV2Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    validate(el) {
        let mainWrapper = el.closest(`.${questionnaireStageV2Semantics.mainWrapClass}`);
        let checkGroups = mainWrapper.find(`.${questionnaireStageV2Semantics.groupIdentityElement}`);

        if (checkGroups.length > 0) {
            let valid = true;
            checkGroups.each((index, item) => {
                let checked = $(item).find(`
                .${questionnaireStageV2Semantics
                        .optionElement}[${questionnaireStageV2Semantics.dataCheckAttribute}=true]`);
                if (checked.length === 0) {
                    valid = false;
                }
            })

            if (valid) {
                let nextStageId = el.closest(`.${questionnaireStageV2Semantics.mainWrapClass}`)
                    .attr(questionnaireStageV2Semantics.nextStageIdAttribute);
                if (nextStageId !== undefined) {
                    window.widgets.questionnaireEntityV1.nextStageFromOptionItem(el, nextStageId)
                } else {
                    if (mainWrapper.attr(questionnaireStageV2Semantics.finalAttribute) === 'true') {
                        window.widgets.questionnaireEntityV1.finalStage(el)
                    }
                }
            }
        }
    }

    reInit(instanceId) {
        QuestionnaireStageV2.init();
    }
}