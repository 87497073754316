import $ from 'jquery';

/**
 * [@mvp] потом доделать, вынести логику из mini-cart-data-v1 сюда
 *
 * MiniCartDataItemV1 widget configs
 */
const miniCartDataItemV1Semantics = {
    'mainWrapClass': 'mini-cart-data-item-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'initializeContainerValueAttribute': 'isInitialized',
    'cartCounterElement': 'mini-cart-data-item-v1__cart-counter',
    'cartCounterDecreaseElement': 'mini-cart-data-item-v1__decrease-counter',
    'cartCounterIncreaseElement': 'mini-cart-data-item-v1__increase-counter',
    'cartCounterRemoveElement': 'mini-cart-data-item-v1__remove-item',
    'cartElementContainer': 'mini-cart-data-item-v1',
    'maxCount': 99,
    'minCount': 1,
};

export class MiniCartDataItemV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {

        let mainWrapClass = $(`.${miniCartDataItemV1Semantics.mainWrapClass}`);

        if (!mainWrapClass || mainWrapClass.length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.miniCartDataItemV1) {
            window.widgets.miniCartDataItemV1 = new MiniCartDataItemV1();
        }

        /**
         * Проверяем другие инстансы
         */
        mainWrapClass.each((index, el) => {
            let isInitialized =
                ($(el).attr(`${miniCartDataItemV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.miniCartDataItemV1.registerInstance($(el));
                window.widgets.miniCartDataItemV1.registerListeners($(el));
            }
        });
    }


    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${miniCartDataItemV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${miniCartDataItemV1Semantics.mainWrapClass}`);
            el.attr(`${miniCartDataItemV1Semantics.isInitializedAttribute}`, true);
            window.widgets.miniCartDataItemV1.pluginInstances[instanceId] = object;
        } catch (e) {
            throw e;
        }
    }

    registerListeners(el) {
    }
}