import $ from 'jquery';

const ChangeTypeEnum = {
    changeAttribute: "changeAttribute",
    removeAttribute: "removeAttribute",
    addClasses: "addClasses",
    removeClasses: "removeClasses",
    replaceContent: "replaceContent",
    replaceUrl: "replaceUrl",
}

const ChangerModule = {};

class Changer
{
    isLog = false;

    /**
     * @var {array}
     */
    rawChanges = [];
    
    /**
     * @var {array}
     */
    changes = [];

    /**
     * @var {jQuery|null}
     */
    contextElement = null;

    buildChanges() {
        let self = this;
        self.rawChanges.forEach((rawChange) => {
            let changeClassName = `${
                rawChange.changeType.charAt(0).toUpperCase() 
                    + 
                rawChange.changeType.slice(1)
            }Change`;
            let changeObject = new ChangerModule[changeClassName]();
            for (let key in rawChange) {
                changeObject[key] = rawChange[key]; 
            }
            changeObject.isLog = self.isLog;
            changeObject.contextElement = self.contextElement;
            self.changes.push(changeObject);
        });
    }

    runChanges() {
        let self = this;
        self.changes.forEach((change) => {
            change.run();
        });
    }    
}

ChangerModule.BaseChange = class
{
    isLog = false;

    changeType;
    elementSelector;
    parentSelector = null;

    /**
     * @var {jQuery|null}
     */
    contextElement = null;

    targetElement;

    resolveTargetElement() {
        let self = this;
        if (self.contextElement != null) {
            self.targetElement = self.contextElement.find(self.elementSelector);
        } else {
            self.targetElement = $(self.elementSelector);
        }
        if (self.parentSelector != null) {
            self.targetElement = self.targetElement.closest(self.parentSelector);
        }
        if (!self.targetElement.length) {
            if (self.isLog === true) {
                console.warn(
                    `Not valid resolved TargetElement 
                    with selector [${self.elementSelector}]
                    ${
                        (self.parentSelector !== null)
                            ? ` and parent selector [${self.parentSelector}]`
                            : ''
                    }`
                );
            }
            return false;
        }
        return true;
    }
} 

ChangerModule.ChangeAttributeChange = class extends ChangerModule.BaseChange
{
    attributeName;
    attributeValue;

    run() {
        
        let self = this;
        if (self.resolveTargetElement() === false) {
            return;
        }
        self.targetElement.attr(self.attributeName, self.attributeValue);

        if (self.isLog === true) {
            console.log(
                `Changed attribute [${self.attributeName}]
                to value [${self.attributeValue}] for element:`, 
                self.targetElement
            );
            console.log(`ChangeClassInfo: `, self);
        }

    }
}

ChangerModule.RemoveAttributeChange = class extends ChangerModule.BaseChange
{
    attributeName;

    run() {

        let self = this;
        if (self.resolveTargetElement() === false) {
            return;
        }
        self.targetElement.removeAttr(self.attributeName);

        if (self.isLog === true) {
            console.log(
                `Remove attribute [${self.attributeName}] for element:`, 
                self.targetElement
            );
            console.log(`ChangeClassInfo: `, self);
        }

    }
}

ChangerModule.AddClassesChange = class extends ChangerModule.BaseChange
{
    classes = [];

    run() {

        let self = this;
        if (self.resolveTargetElement() === false) {
            return;
        }
        self.targetElement.addClass(self.classes.join(' '));

        if (self.isLog === true) {
            console.log(
                `Add classes [${self.classes.join(' ')}] for element:`, 
                self.targetElement
            );
            console.log(`ChangeClassInfo: `, self);
        }

    }
}

ChangerModule.RemoveClassesChange = class extends ChangerModule.BaseChange
{
    classes = [];

    run() {
        let self = this;
        if (self.resolveTargetElement() === false) {
            return;
        }
        self.targetElement.removeClass(self.classes.join(' '));

        if (self.isLog === true) {
            console.log(
                `Remove classes [${self.classes.join(' ')}] for element:`, 
                self.targetElement
            );
            console.log(`ChangeClassInfo: `, self);
        }

    }
}

ChangerModule.ReplaceContentChange = class extends ChangerModule.BaseChange
{
    content = null;

    run() {
        let self = this;
        if (self.resolveTargetElement() === false) {
            return;
        }
        self.targetElement.empty().html(self.content);

        if (self.isLog === true) {
            console.log(
                `Change content to [${self.content}] for element:`, 
                self.targetElement
            );
            console.log(`ChangeClassInfo: `, self);
        }

    }
}

ChangerModule.ReplaceUrlChange = class extends ChangerModule.BaseChange
{
    url;

    run() {

        let self = this;
        window.location.href = self.url;
        // history.pushState(null, null, self.url);

        if (self.isLog === true) {
            console.log(
                `Replace location.href with URL=[${self.url}]`
            );
            console.log(`ChangeClassInfo: `, self);
        }

    }
}

export {
    Changer, ChangeTypeEnum
};