import $ from 'jquery';
import LIVR from 'livr';
import ErrorCodes from '../../services/validator/ErrorCodes';
import Validator from '../../services/validator/Validator';
import { initPopup } from '../../scripts/initPopup';

/**
 * NOTE: this module use the var `window.attachFiles`
 * which is declared and installed in the file: 
 * `app/frontend/assets/js/scripts/form.js`
 * This is a temporary measure after the introduction of a normal JS architecture, 
 * you need to rewrite the code below and issue a unified standard 
 * for collecting and transferring files in forms via JS
 */

const semantics = {
    'wrapClass': 'subscription-request-v1',
    'formName': 'SubscriptionRequestV1Form',
    'formItemClass': 'form__input',
    'errorWrapClass': 'invalid-feedback',
    'formBlockClass': 'enkod-subscribe__start',
    'successBlockClass': 'enkod-subscribe__success',
};

let validatorRules = {
    [`${semantics.formName}[email]`]: ['required', 'email'],
};

const validator = new LIVR.Validator(validatorRules);

$(function() {

    var subscriptionRequestV1 = new SubscriptionRequestV1();
    subscriptionRequestV1.subscribe();
    
});

class SubscriptionRequestV1
{
    constructor() {
        this.mainEl = $(`.${semantics.wrapClass}`);
    }

    resetError() {
        this.mainEl.find(`.${semantics.formItemClass} input, .${semantics.formItemClass} textarea`).removeClass('_error');
        this.mainEl.find(`.${semantics.formItemClass} .${semantics.errorWrapClass}`).removeClass('show');
    }

    showError(validator) {
        Object.entries(validator.getErrors()).forEach(([name, errCode]) => {
            const field = this.mainEl.find(`[name="${name}"]`);
            if (field.length) {
                switch (errCode) {
                    case ErrorCodes.REQUIRED:
                        field.addClass('_error');
                    break;
                    default:
                        field
                            .closest(`.${semantics.formItemClass}`)
                            .find(`.${semantics.errorWrapClass}`)
                            .addClass('show')
                            .empty()
                            .html($('<p>', {
                                class: '_alert _alert-error',
                            }).html(Validator.generateError(errCode)));
                    break;
                }
            } 
        });
    }

    subscribe() {

        let self = this;

        $(this.mainEl).on('submit', 'form', function(e) {

            e.preventDefault();
            self.resetError();
            const $form = $(this);
            const formData = new FormData(this);
            const formDataObj = {};
            formData.forEach((value, key) => (formDataObj[key] = value));

            // set files
            let fileInputId = $form.find('.js-file').attr('id');
            formData.delete(`${semantics.formName}[files]`);
            formData.delete(`${semantics.formName}[files][]`);
            if (
                typeof window.attachFiles[fileInputId] != 'undefined'
                    &&
                window.attachFiles[fileInputId].length
            ) {
                for (let i = 0; i < window.attachFiles[fileInputId].length; i++) {
                    formData.append(`${semantics.formName}[files][${i}]`, window.attachFiles[fileInputId][i]);
                }
            }

            const isValidForm = validator.validate(formDataObj);
            if (isValidForm === false) {
                self.showError(validator);
                return false;
            }
    
            $.ajax({
                url: this.action,
                method: this.method,
                processData: false,
                contentType: false,
                cache: false,
                data: formData,
                success: function (data) {
                    self.mainEl.find(`.${semantics.formBlockClass}`).hide();
                    self.mainEl.find(`.${semantics.successBlockClass}`).show();
                    // add auto-processing YM-goal
                    // ym_goal('success_registration_xrap'); 
                },
                error: function(data) {
                    // 
                }
            });

        });    

    }    
}