// LocationSwitcher
const locationSwitcherWrapTemplate = `
    <div class="menu-bar__second-level-wrap menu-bar__second-level-wrap_location-switcher">
        <div class="menu-bar__breadcrumb-wrap">
            <div class="menu-bar__breadcrumb-item">
                <svg class="search-form__icon" width="6" height="10">
                    <use xlink:href="${iconSVG}#corner-left-icon"></use>
                </svg>
                <a href="#" class="js-2nd-submenu-close">Назад</a>
            </div>
        </div>
        {locationSwitcherSearch}
        {locationSwitcherList}
    </div>
`;

const locationSwitcherSearchTemplate = `
    <div class="menu-bar__top-wrap">
        <div class="search-form__input-wrap">
            <input name="term" type="text" placeholder="Введите ваш город" 
            class="search-form__input js-search-input" autocomplete="off">
            <button class="search-form__btn" type="submit">
                <span class="visually-hidden">Введите ваш город</span>
                <svg class="search-form__icon" width="18" height="18">
                    <use xlink:href="${iconSVG}#search-reflection-icon"></use>
                </svg>
            </button>
        </div>
    </div>
`;

const locationSwitcherListTemplate = `
    <div class="menu-bar__scroll-list thin-scrollbar">
        <div class="menu-bar__catalog-list">
            {locationSwitcherItems}
        </div>
    </div>
`;

const locationSwitcherItemTemplate = `
    <div class="menu-bar__item">
        <a href="#" class="js-city-select" data-id="{cityId}">
            {cityName}
        </a>
    </div>
`;
// \LocationSwitcher

// CatalogSubmenu2nd
const catalogSubmenu2ndWrapTemplate = `
    <div class="menu-bar__second-level-wrap menu-bar__second-level-wrap_catalog-submenu-2nd">
        <div class="menu-bar__breadcrumb-wrap">
            <div class="menu-bar__breadcrumb-item js-2nd-submenu-close">
                <svg class="search-form__icon" width="6" height="10">
                    <use xlink:href="${iconSVG}#corner-left-icon"></use>
                </svg>
                <a href="#">{breadcrumb}</a>
            </div>
        </div>     
        {catalogMainList}
        {catalogSpecialList}
    </div>
`;

const catalogSubmenu2ndMainListTemplate = `
    <div class="menu-bar__scroll-list thin-scrollbar">
        <div class="menu-bar__catalog-list">
            {catalogSubmenuMainItems}
        </div>
    </div>
`;

const catalogSubmenu2dMainItemTemplate = `
    <div class="menu-bar__item js-3rd-level-activator" data-allurl="{allUrl}" data-allTitle="{allTitle}"
        {catalogSubmenuWrapAttribute}="{catalogSubmenuWrapAttributeValue}"
        {catalogSubmenu3rdWrapAttribute}="{catalogSubmenu3rdWrapAttributeValue}">
        <a href="{href}">
            {text}
        </a>
        <svg class="menu-bar__item-icon" width="6" height="10">
            <use xlink:href="${iconSVG}#corner-right-icon"></use>
        </svg>
    </div>
`;

const catalogSubmenu3rdMainWrapTemplate = `
    <div class="menu-bar__second-level-wrap menu-bar__second-level-wrap_catalog-submenu-3rd">
        <div class="menu-bar__breadcrumb-wrap">
            <div class="menu-bar__breadcrumb-item js-3rd-submenu-close">
                <svg class="search-form__icon" width="6" height="10">
                    <use xlink:href="${iconSVG}#corner-left-icon"></use>
                </svg>
                <a href="#">{breadcrumb}</a>
            </div>
        </div>       
        {catalogMainList}
    </div>
`;

const catalogSubmenu3rdMainListTemplate = `
    <div class="menu-bar__scroll-list thin-scrollbar">
        <div class="menu-bar__catalog-list">
            <a class="menu-bar__alllink" href="{allUrl}">{allTitle}</a>
            {catalogSubmenuMainItems}
        </div>
    </div>
`;

const catalogSubmenu3rdMainItemTemplate = `
    <div class="menu-bar__item js-sub-level-activator">
        <div class="menu-bar__item-content-wrap">
            <span>
                {text}
            </span>
            <svg class="menu-bar__item-icon" width="10" height="6">
                <use xlink:href="${iconSVG}#corner-down-icon"></use>
            </svg>
        </div>
        {catalogDropdownList}
    </div>
`;

const catalogSubmenu3rdDropdownListTemplate = `
    <div class="menu-bar__item-dropdown-wrap">
        {catalogSubmenuDropdownItems}
    </div>
`;

const catalogSubmenu3rdDropdownItemTemplate = `
    <div class="menu-bar__item-dropdown-item {dotModifier}">
        <a href="{href}">
            {text}
        </a>
    </div>
`;
// \CataloSubmenu2nd

export {
    catalogSubmenu3rdDropdownItemTemplate,
    catalogSubmenu3rdDropdownListTemplate,
    catalogSubmenu3rdMainItemTemplate,
    catalogSubmenu3rdMainListTemplate,
    catalogSubmenu3rdMainWrapTemplate,
    catalogSubmenu2dMainItemTemplate,
    catalogSubmenu2ndMainListTemplate,
    catalogSubmenu2ndWrapTemplate,
    locationSwitcherItemTemplate,
    locationSwitcherListTemplate,
    locationSwitcherSearchTemplate,
    locationSwitcherWrapTemplate
};
