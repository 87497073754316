import $ from 'jquery';
import { 
    size,
    slickCommonOptions,
} from "../../scripts/config";

/**
 * AdvantagesV1 widget configs
 */
const advantagesV1Semantics = {
    'mainWrapClass': 'advantages-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'sliderElementClass': 'advantages-v1__js-advantages-slide'
};


$(function () {
    AdvantagesV1.init();
})

class AdvantagesV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${advantagesV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.advantagesV1) {
            window.widgets.advantagesV1 = new AdvantagesV1();
        }

        /**
         * Checking other instances
         */
        $(`.${advantagesV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${advantagesV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.advantagesV1.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${advantagesV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        let commonPluginOptions =
            el.data(
                `${advantagesV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                destroyOn: size.desktop,
                arrows: false,
                variableWidth: true,
                variableHeight: false,
                adaptiveHeight: false,
                infinite: false,
            },
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${advantagesV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);
            el.attr(`${advantagesV1Semantics.isInitializedAttribute}`, true);
            window.widgets.advantagesV1.pluginInstances[instanceId] = slick;
        } catch(e) {
            el.hide();
            throw e;
        }
    }
}