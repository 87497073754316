import $ from 'jquery';
import { initPopup } from './initPopup';

// TODO[justMvp]: rewrite all JS-code as components
$(() => {
    initDefaultForm();
})
// стандартные формы
function initDefaultForm($body) {

    const antispamSeconds = 2; // Через сколько секунд можно будет отправить форму после последнего изменения

    if(!$body) {
      $body = $('body');
    }

    // прикрепляемые картинки по input id
    window.attachFiles = {
        // id: filesArr
    }

    // $('.js-form', $body).on('submit', function(e) {

    //     const $form = $(this);
    //     if ($form.attr('id') == 'exchangeForm') {
    //         return true;
    //     }

    //     e.preventDefault();


    //     const isCorrect = isFormCorrect($form);
    //     const popupCheckKey = $form.data('popup-check');
    //     if (popupCheckKey){
    //        if (!popupCheck.has(popupCheckKey)){
    //             return;
    //        }
    //     }
    //     const formId = $form.attr('id');

    //     if(!isCorrect) {
    //         $form.find('._error')[0].focus();
    //         return
    //     }


    //     // Если это форма с антиспамом, то пропустим ее только если уже прошло достаточно времени после последнего изменения
    //     if ($form.hasClass('js-antispam-form')) {
    //       const time = $form.attr('time');
    //       if(time){
    //           if ((getASeconds() - time) <= antispamSeconds) {
    //               return false;
    //           }
    //       }else{
    //           return false;
    //       }

    //     }


    //     const data = new FormData(this);
    //     // прикрепляем файлы
    //     let fileInputId = $form.find('.js-file').attr('id');
    //     if($form.attr('id') == 'contactForm') {
    //       fileInputId = $form.find('.js-text-file').attr('id');
    //     }
    //     if(fileInputId || $form.attr('id') == 'contactForm') {
    //       for (var i = 0; i < window.attachFiles[fileInputId].length; i++) {
    //         // if ($form.attr('id') == 'reclamationForm') {
    //         //   data.append('ReclamationForm[window.attachFiles]['+i+']', window.attachFiles[fileInputId][i]);
    //         // } else {
    //         //   data.append('window.attachFiles['+i+']', window.attachFiles[fileInputId][i]);
    //         // }

    //         data.append('window.attachFiles['+i+']', window.attachFiles[fileInputId][i]);

    //       }
    //     }

    //     if (formId == 'changePhoneForm') { // не будем сабмитить форму, если не изменен номер телефона
    //       const $phone = $form.find('[name="phone"]');
    //       if ($phone.val() == $phone.data('phone')) {
    //         return;
    //       }
    //     }


    //     $.ajax({
    //         url: this.action,
    //         method: this.method,
    //         processData: false,
    //         contentType: false,
    //         cache: false,
    //         data: data,
    //         success: function (data) {
    //             //console.log(data);
    //             if (data['status']) {

    //               const successPopup = $form.closest('.js-form').find('.js-success-popup');
    //               $.magnificPopup.open(initPopup.prototype.getConfig(successPopup))

    //               if (formId != 'changePhoneForm') {
    //                 $form[0].reset();
    //               }
    //               $form.find('.js-file-thumbnail')
    //                   .removeClass('_interactive js-file-label-double')
    //                   .html('')
    //                   .removeAttr('data-full')
    //                   .first()
    //                   .addClass('_interactive js-file-label-double');

    //             } else {
    //               const failPopup = $form.closest('.js-form').find('.js-error-popup');
    //               $.magnificPopup.open(initPopup.prototype.getConfig(failPopup))
    //             }
    //         },
    //         error() {
    //             const failPopup = $form.closest('.js-form').find('.js-error-popup');
    //             $.magnificPopup.open(initPopup.prototype.getConfig(failPopup))
    //         }
    //     });
    // });

    $('.js-phone-input, .js-email', $body).on('input', function() {
        $(this).removeClass('_error');
    });

    initAttachFile();

    function initAttachFile() {
        function generateRemoveButton(fileNum) {
            return `
                <button class="form__file-remove js-file-remove" data-file-num="${fileNum}" type="button">
                    <svg class="form__remove-icon">
                        <use xlink:href="`+iconSVG+`#close"></use>
                    </svg>
                </button>
            `
        }

        $('.js-file-container .js-file, .js-file-container .js-text-file', $body).each(function() {
            window.attachFiles[this.id] = [];
        });

        $('.js-file-container .js-file', $body).on('change', function() {
            const inputFiles = this.files;
            if(inputFiles.length === 0) return
            
            const $form = $(this).closest('.js-form');
            const $container = $(this).closest('.js-file-container');
            const id = this.id;
            const max = $container.find('.js-file-thumbnail').length
            let countAddedImages = 0;

            if(window.attachFiles[id].length === max) {
                openMaxSizePopup($form);
                this.value = null;
                return
            }

            for (let i=0; i < inputFiles.length; i++) {
                if(window.attachFiles[id].length === max) {
                    openMaxSizePopup($form)
                    break
                } else {
                    countAddedImages++;
                    window.attachFiles[id].push(inputFiles[i]);
                }
            }

            renderThumbnails(id, true, countAddedImages);

            this.value = null;
        });

        $('.js-text-file', $body).on('change', function() {
            const id = this.id;
            const inputFiles = this.files;
            if(inputFiles.length === 0) return

            for (let i=0; i < inputFiles.length; i++) {
              window.attachFiles[id].push(inputFiles[i]);
            }

            const name = inputFiles[0].name;
            $(this)
                .closest('.js-file-container')
                .find('.js-file-name')
                .text(name);

        });

        function renderThumbnails(id, isAdd, count) {
            const $container = $(`#${id}`).closest('.js-file-container');
            if(!isAdd) {
                $container.find('.js-file-thumbnail').each(function() {
                    $(this)
                        .removeClass('_interactive js-file-label-double')
                        .html('')
                        .removeAttr('data-full');
                });
            }

            window.attachFiles[id].forEach((file, i, arr) => {
                if(isAdd && i < arr.length - count) return
                const $emptySlot = $container.find('.js-file-thumbnail:not([data-full])').first();
                const img = document.createElement('img');
                img.src = window.URL.createObjectURL(file);
                $emptySlot
                    .append(img, generateRemoveButton(i))
                    .attr('data-full', '')
                    .removeClass('_interactive js-file-label-double');
            });

            $container.find('.js-file-thumbnail:not([data-full])').first().addClass('_interactive js-file-label-double');
        }


        $(document).on('click', '.js-file-remove', function() {
            const $this = $(this);
            const filePosition = $this.data('file-num');

            const inputId = $this.closest('.js-file-container').find('.js-file').attr('id');
            window.attachFiles[inputId].splice(filePosition, 1);
            renderThumbnails(inputId);
        });
    }

    $(document).on('click', '.js-file-label-double', function() {
        $(this)
            .closest('.js-file-container')
            .find('.js-file-label')
            .trigger('click');
    });



    //$('.js-antispam-form').attr('time', getASeconds());

    // При изменении данных в форме обновим временную метку
    $('.js-antispam-form input, .js-antispam-form textarea').on('change', function() {
      const $form = $(this).closest('.js-antispam-form');
      $form.attr('time', getASeconds());
    });

    function getASeconds() { // Сколько секунд прошло с Jan 1, 1970, 00:00:00.000 GMT
      return parseInt(new Date().getTime() / 1000);
    }

    $('.js-form').each(function(i) {
      if ($(this).find('.js-form-check').length > 0) {
        if (!$(this).find('.js-form-check input[type="checkbox"]').is(':checked')) {
          $(this).find('button[type="submit"]').prop('disabled', true);
        }
      }
    });

    $('.js-form-check input[type="checkbox"]', $body).on('change', function(e) {
      const $form = $(this).closest('.js-form');
      const $btn = $form.find('button[type="submit"]');
      if ($(this).is(':checked')) {
        $btn.prop('disabled', false);
      } else {
        $btn.prop('disabled', true);
      }
    });

}
