import {
    matchMediaQuery
} from '../../scripts/helpers';
import $ from 'jquery';
import Masonry from 'masonry-layout';
import jqueryBridget from 'jquery-bridget';

jqueryBridget('masonry', Masonry, $);

$(() => {
    // only desktop
    if (!matchMediaQuery('(min-width: 1024px)')) {
        return false;
    }

    let menuTracker = new HoverMenuTracker();
    let submenuTracker = new HoverSubmenuTracker();
});

class HoverMenuTracker 
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor

    constructor() {
        this.timeoutCloseMenu = null;
        this.semantics = {
            'menuBarWrapClass': 'menu-bar__wrap',
            'menuListClass': 'menu-bar__catalog-list',
            'menuItemClass': 'menu-bar__item',
            'menuItemActiveModifierClass': 'menu-bar__item_active',
            'containerPlaceClass': 'submenu',
            'containerPlaceActiveModifierClass': 'submenu_active',
            'containerItemClass': 'submenu__wrapper',
            'containerItemActiveModifierClass': 'submenu__wrapper_active',
            'attrContainerItemId': 'data-hmt-id',
            'submenuItemClass': 'submenu__submenu-wrapper',
            'submenuItemActiveModifierClass': 'submenu__submenu-wrapper_active',
        };
        this.subscribe();
        // for testing: if you need to make one of the menu items active, 
        // comment out the subscription to the events above 
        // and uncomment the lines below
        // $(`.${this.semantics.containerPlaceClass}`).addClass(
        //     this.semantics.containerPlaceActiveModifierClass
        // );
        // $(`.${this.semantics.containerItemClass}:nth-child(1)`).addClass(
        //     this.semantics.containerItemActiveModifierClass
        // );
    }

    /**
     * Add listener to events
     * @return void
     */
    subscribe() {
        let self = this;
        var delay=200, setTimeoutConst;
        $(`.${self.semantics.menuItemClass}`).on('mouseover', function(e) {
            setTimeoutConst = setTimeout(function() {
                if (self.timeoutCloseMenu !== null) {
                    clearTimeout(self.timeoutCloseMenu);
                }
                let selector =
                    $(e.target).hasClass(`${self.semantics.menuItemClass}`)
                        ? $(e.target)
                        : $(e.target).parent(`.${self.semantics.menuItemClass}`);
                self.activate(selector);
            }, delay);
        });
        $(`.${self.semantics.containerItemClass}`).on('mouseover', function(e) {
            clearTimeout(setTimeoutConst);
            if (self.timeoutCloseMenu !== null) {
                clearTimeout(self.timeoutCloseMenu);
            }
            let containerItemSelector = 
                $(e.target).hasClass(`${self.semantics.containerItemClass}`)
                ? $(e.target)
                : $(e.target).parent(`.${self.semantics.containerItemClass}`);
            let containerItemId = containerItemSelector.attr(`${self.semantics.attrContainerItemId}`);
            let menuItemSelector = $(`.${self.semantics.menuListClass}`)
                .find(`[${self.semantics.attrContainerItemId}="${containerItemId}"]`);
            if (menuItemSelector.length > 0) {
                self.activate(menuItemSelector);
            }
        });
        // globally subscribe (close menu if mouseenter only menuBarWrapClass && containerItemClass)
        $(document).on(
            'mouseover', 
            function(e) {

                let containerItemSelector = 
                    $(e.target).hasClass(`${self.semantics.containerItemClass}`)
                        ? $(e.target)
                        : $(e.target).closest(`.${self.semantics.containerItemClass}`);
                let menuBarItemSelector = 
                    $(e.target).hasClass(`${self.semantics.menuBarWrapClass}`)
                        ? $(e.target)
                        : $(e.target).closest(`.${self.semantics.menuBarWrapClass}`);

                if (containerItemSelector.length === 0
                    && menuBarItemSelector.length === 0) {
                    clearTimeout(setTimeoutConst);
                    self.closeMenu();
                }


            }
        );
    }

    /**
     * Activate element and show menu wrapper
     * @param active element
     * @return bool status
     */
    activate(el) {
        let containerItemId = el.attr(`${this.semantics.attrContainerItemId}`);
        let containerItem = $(`.${this.semantics.containerPlaceClass}`)
            .find(`[${this.semantics.attrContainerItemId}="${containerItemId}"]`);
        if (!containerItem.length ||
            containerItem.hasClass(`${this.semantics.containerItemActiveModifierClass}`)) {
            return false;
        }
        $(`.${this.semantics.containerPlaceClass}`)
            .addClass(`${this.semantics.containerPlaceActiveModifierClass}`);
        $(`.${this.semantics.menuItemClass}`)
            .removeClass(`${this.semantics.menuItemActiveModifierClass}`);
        $(`.${this.semantics.containerItemClass}`)
            .removeClass(`${this.semantics.containerItemActiveModifierClass}`);
        el.addClass(`${this.semantics.menuItemActiveModifierClass}`);

        containerItem.addClass(`${this.semantics.containerItemActiveModifierClass}`);
        /*var height = $(window).height()-$('.header-wrap').height();
        if(height<600){
            var cssHeight = height-50+'px !important';
        }else{
            var cssHeight = '60rem';
        }
        $(`.${this.semantics.containerPlaceClass}`).css('height',cssHeight);
        $(`.${this.semantics.containerItemClass}`).css('grid-template-rows',cssHeight);*/
        // first apply init masonry
        masonryActivator(containerItem.find(
            `.${this.semantics.submenuItemClass}` + 
            `.${this.semantics.submenuItemActiveModifierClass}`
        ));
        return true;
    }
    
    /**
     * Closed main menu
     * @return void
     */
    closeMenu() {
        let self = this;
        this.timeoutCloseMenu = setTimeout(() => {
            $(`.${self.semantics.containerPlaceClass}`)
                .removeClass(`${self.semantics.containerPlaceActiveModifierClass}`);
            $(`.${self.semantics.menuItemClass}`)
                .removeClass(`${self.semantics.menuItemActiveModifierClass}`);
            $(`.${self.semantics.containerItemClass}`)
                .removeClass(`${self.semantics.containerItemActiveModifierClass}`);
        }, 500);
    }
}

/**
 * Class to tracker mouse movements and active submenu items in main menu
 */
class HoverSubmenuTracker 
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor

    constructor() {
        this.semantics = {
            'containerItemClass': 'submenu__wrapper',
            'menuListClass': 'submenu__main-menu-list',
            'menuItemClass': 'submenu__main-menu-item',
            'menuItemActiveModifierClass': 'submenu__main-menu-item_active',
            'submenuPlaceClass': 'submenu__submenu-place',
            'submenuItemClass': 'submenu__submenu-wrapper',
            'submenuItemActiveModifierClass': 'submenu__submenu-wrapper_active',
            'attrSubmenuItemId': 'data-hst-id',
        };
        this.subscribe();
    }

    /**
     * Add listener to events
     * @return void
     */
    subscribe() {
        let self = this;
        $(`.${self.semantics.menuListClass} .${self.semantics.menuItemClass}`).on(
            'mouseover', 
            function(e) {
                let selector =
                    $(e.target).hasClass(`${self.semantics.menuItemClass}`)
                        ? $(e.target)
                        : $(e.target).parent(`.${self.semantics.menuItemClass}`);
                self.activate(selector);
            }
        );
    }

    /**
     * Activate element and show submenu
     * @param active element
     * @return bool status
     */
    activate(el) {
        if (el.hasClass(`${this.semantics.menuItemActiveModifierClass}`)
            || !this.trajectoryChecker()) {
            return false;
        }
        let activeContainer = el.parents(`.${this.semantics.containerItemClass}`);
        activeContainer.find(`.${this.semantics.submenuItemClass}`)
            .removeClass(`${this.semantics.submenuItemActiveModifierClass}`);
        activeContainer.find(`.${this.semantics.menuItemClass}`)
            .removeClass(`${this.semantics.menuItemActiveModifierClass}`);
        el.addClass(`${this.semantics.menuItemActiveModifierClass}`);
        let submenuId = el.attr(`${this.semantics.attrSubmenuItemId}`);
        let submenuWrapper = activeContainer.find(
            `.${this.semantics.submenuPlaceClass}` +
            ` [${this.semantics.attrSubmenuItemId}="${submenuId}"]`
        )
        if (submenuWrapper.length > 0) {
            submenuWrapper.addClass(`${this.semantics.submenuItemActiveModifierClass}`);
            // apply masonry for each submenu change
            masonryActivator(submenuWrapper);
        }
        return true;
    }

    /**
     * TODO[task]
     * TODO: to check the trajectory of the mouse movement over the 
     * menu items through several points of movement, focusing on 
     * the ordinate axis, if the shift of the points is large, 
     * the user is likely to move to the submenu and 
     * there is no need to switch a menu.
     * @return boolean
     */
    trajectoryChecker() {
        return true;
    }

}

/**
 * Activator for Masonry plugin (work only for the visible content)
 * @param {JQuery} selector 
 * @return void
 */
function masonryActivator(selector) {
    const tagBlockSelector = '.submenu__submenu-tag-block';
    // init/re-init Masonry plugin
    var height = $(window).height()-$('.header-wrap').height();
    if(height < 600 ){
       var cssHeight = (height-100)+'px';
    }else{
        var cssHeight = '56rem';
    }
    $(selector).masonry({
        itemSelector: tagBlockSelector,
        // gutter: 60,
        horizontalOrder: true,
        percentPosition: true,
        singleMode: false,
        isResizable: true,
        animationOptions: { 
            queue: false, 
            duration: 500 
        },
        containerStyle: {
            'height': cssHeight,
            'max-height': cssHeight,
            'min-height': cssHeight,
        }
    });
}