import $ from 'jquery';
import {CartModule} from '../../services/cart/Cart';

const addToCartV1Semantics = {
    'initiatorElementSelector': '[add-to-cart-v1]',
    'wrapAttributeName': 'add-to-cart-v1',
    'isInCartAttributeName': 'data-add-to-cart-v1-is-in-cart',
    'countAttributeName': 'data-add-to-cart-v1-count',
    'productTypeIdAttributeName': 'data-add-to-cart-v1-product-type-id',
    'idAttributeName': 'data-add-to-cart-v1-id',
    'virtualModifierIdAttributeName': 'data-add-to-cart-v1-virtual-modifier-id',
    'inLabelAttributeName': 'data-add-to-cart-v1-in-label',
    'outLabelAttributeName': 'data-add-to-cart-v1-out-label',
    'buttonDisabledClassName': 'button-v1_disabled',
};

$(function () {

    let addToCartV1 = new AddToCartV1();
    addToCartV1.subscribe();
    addToCartV1.checkSelected();
    window.widgets.addToCartV1 = addToCartV1;
});

class AddToCartV1 {

    subscribe() {

        let self = this;

        $(document).on(
            'click',
            addToCartV1Semantics.initiatorElementSelector,
            function (e) {
                e.preventDefault();
                let initiatorElement =
                    $(e.target).has(
                        `[${addToCartV1Semantics.wrapAttributeName}]`
                    )
                        ? $(e.target)
                        : $(e.target).closest(
                            `[${addToCartV1Semantics.wrapAttributeName}]`
                        );

                // check if product already in cart
                if (initiatorElement.has(
                    `[${addToCartV1Semantics.isInCartAttributeName}="1"]`
                ).length) {
                    return true;
                }

                let productTypeId = initiatorElement.attr(
                    addToCartV1Semantics
                        .productTypeIdAttributeName
                );
                let id = initiatorElement.attr(
                        addToCartV1Semantics
                            .idAttributeName
                    );
                let count = initiatorElement.attr(
                    addToCartV1Semantics
                        .countAttributeName
                );
                let virtualModifierId = initiatorElement.attr(
                    addToCartV1Semantics
                        .virtualModifierIdAttributeName
                );

                CartModule.cart.addOrChange(
                    productTypeId,
                    id,
                    count,
                    virtualModifierId,
                    function () {
                        self.checkSelected()
                    }
                );

                // вызываем метод для обновления контента в popup Окне.
                // тут ли оно должно быть?
                if (window.widgets?.modalWindowAddToCartV1?.onOpenPopup) {
                    window.widgets.modalWindowAddToCartV1.onOpenPopup(
                        productTypeId,
                        id,
                        virtualModifierId
                    );
                }
                else {
                   // throw new Error('onOpenPopup не найден');
                }
            }
        );

    }

    checkSelected() {
        if (!(CartModule?.cart?.getProductSet?.() instanceof Object)) {
            return;
        }
        let productSet = CartModule.cart.getProductSet().products;

        for (let key in productSet) {

            let selector = `[${addToCartV1Semantics.idAttributeName}="${productSet[key]['id']}"]`;

            //if (productSet[key]['productTypeId']) {
                selector += `[${addToCartV1Semantics.productTypeIdAttributeName}="${productSet[key]['productTypeId']}"]`;
            //}

            if ('virtualModifier' in productSet[key]) {
                let virtualModifierValue = productSet[key]['virtualModifier'] ? productSet[key]['virtualModifier'] : '';
                selector += `[${addToCartV1Semantics.virtualModifierIdAttributeName}="${virtualModifierValue}"]`;
            }
            /*
            else {
                selector += `:not([${addToCartV1Semantics.virtualModifierIdAttributeName}]), [${addToCartV1Semantics.virtualModifierIdAttributeName}=""]`;
            }*/

            let foundElement = $(selector);

            foundElement.attr(
                addToCartV1Semantics.countAttributeName,
                productSet[key]['count'] + 1
            );
            if (foundElement.length) {
                this.toggleElement(foundElement);
            }
        }
    }

    toggleElement(elements) {
        $.each(elements, function (key, element) {
            let $element = $(element);
            $element.empty().html(
                $element.attr(
                    addToCartV1Semantics.outLabelAttributeName
                )
            );
            $element.addClass(
                addToCartV1Semantics.buttonDisabledClassName
            );
        });
    }
}