import {
    matchMediaQuery
} from '../../scripts/helpers';
import $ from 'jquery';

$(() => {
    // only mobile and tablet (to landscape orientation)
    if (!matchMediaQuery('(min-width: 1024px)')) {
        return false;
    }

    let menuTracker = new CommonMenuTracker();
});

/**
 * Tracker for popover (mobile/tablet) menu
 * @param Object semantics object with classes 
 *      of various interface elements in the format:
 *      ```js {
 *      semantics.dropdownWrapClass: 
 *          `class of the element with dropdown menu`,
 *      semantics.intercepterSelector:
 *          `string selector of the parent block to intercept closing`
 *      semantics.submenuOpenModifierClass
 *          `submenu status modifier`
 *      semantics.submenuClass
 *          `class of the submenu html element`
 *      semantics.dropdownItemClass
 *          `class of the item html element`
 *      semantics.dropdownLinkClass
 *          `class of the link html element`
 *      }```
 */
class CommonMenuTracker 
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor

    constructor() {
        this.semantics = {
            'dropdownWrapClass': 'info-bar__dropdown-wrap',
            'intercepterSelector': 'body',
            'submenuOpenModifierClass': 'info-bar__dropdown-wrap_is-open',
            'submenuClass': 'info-bar__submenu',
            'dropdownItemClass': 'info-bar__menu-item',
            'dropdownLinkClass': 'info-bar__menu-link',
        };
        this.subscribe();
    }

    /**
     * Add listener to events
     * @return void
     */
    subscribe() {
        let self = this;
        // intercepting click event
        $(`${self.semantics.intercepterSelector}`).on(
            'click', 
            function(e) {
                self.interceptionClick($(e.target));
            }
        );
        // toogle menu status
        $(`.${self.semantics.dropdownWrapClass}`).on(
            'click', 
            function(e) {
                if ($(e.target).parent(`.${self.semantics.submenuClass}`).length > 0) {
                    return true;
                }
                e.preventDefault();
                let el = 
                    $(e.target).hasClass(self.semantics.dropdownWrapClass)
                        ? $(e.target)
                        : $(e.target).parents(
                                `.${self.semantics.dropdownWrapClass}`
                            );
                self.toggle(el);
            }
        );
        // click on item and re-location from link
        $(`.${self.semantics.dropdownItemClass}`).on(
            'click',
            function(e) {
                let el = $(e.target).hasClass(self.semantics.dropdownLinkClass)
                    ? $(e.target)
                    : $(e.target).find(
                        `.${self.semantics.dropdownLinkClass}`
                    );
                let href = el.attr('href');
                if (href !== '#' && href.length > 0) {
                    window.location = href;
                }
            }
        )
    }

    /**
     * Toggle menu status
     * @param JQuery el jquery wrapped html element
     * @return void
     */
    toggle(el) {
        let self = this;
        el.hasClass(self.semantics.submenuOpenModifierClass)
            ? el.removeClass(self.semantics.submenuOpenModifierClass)
            : el.addClass(self.semantics.submenuOpenModifierClass);
    }
    
    /**
     * Interception of a click outside the menu
     * @param JQuery el jquery wrapped html element
     * @return void
     */
    interceptionClick(el) {
        let self = this;
        if (el.hasClass(self.semantics.dropdownWrapClass)
            || el.parent(`.${self.semantics.dropdownWrapClass}`).length > 0) {
            // broke bubbling
            return false;
        }
        $(`.${self.semantics.dropdownWrapClass}`).removeClass(
            self.semantics.submenuOpenModifierClass
        );
        return true;
    }
}