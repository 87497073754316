import $ from 'jquery';

/**
 * Function for checking the match of media queries
 * for more details
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @var string match quries
 * @return bool checking status
 */
function matchMediaQuery(match) {
    return window.matchMedia(match).matches;
}

/**
 * Function replaces variables in the template
 * @param string str template with variable's as {integer|string}
 * @param Array|Object data as Array if use integer key's 
 *      or Object if use assoc key
 * @returns string processing template
 */
function stringInject(str, data) {
    if (typeof str === 'string' && (data instanceof Array)) {
        return str.replace(/({\d})/g, function(i) {
            return data[i.replace(/{/, '').replace(/}/, '')];
        });
    } else if (typeof str === 'string' && (data instanceof Object)) {
        if (Object.keys(data).length === 0) {
            return str;
        }
        for (let key in data) {
            return str.replace(/({([^}]+)})/g, function(i) {
                let key = i.replace(/{/, '').replace(/}/, '');
                if (!data[key]) {
                    return i;
                }
                return data[key];
            });
        }
    } else if (typeof str === 'string' 
        && data instanceof Array === false 
        || typeof str === 'string' 
        && data instanceof Object === false) {
        return str;
    } else {
        return false;
    }
}

function hasScrollbar($elem) {
    if($elem.length < 1) return;
    return $elem[0].scrollHeight > $elem.height();
}

function changeActiveAddress(city) {
    $('.js-address-link._active').removeClass('_active');
    $(`.js-address-link[data-city="${city}"]`).addClass('_active');
}

function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function() {
            timeout = null;
            if(!immediate) func.apply(context, args);
        }

        const callNow = immediate && !setTimeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if(callNow) func.apply(context, args);
    }
}

function declension(oneNominative, severalGenitive, severalNominative, number) {
	number = number % 100;

	return (number <= 14 && number >= 11)
		? severalGenitive
		: (number %= 10) < 5
			? number > 2
				? severalNominative
				: number === 1
					? oneNominative
					: number === 0
						? severalGenitive
						: severalNominative//number === 2
			: severalGenitive
	;
};

function formatText(num, word) {
    let noun;
    switch(word) {
        case 'производитель':
          noun = declension('производитель', 'производителей', 'производителя', num);
          break
        case 'бренд':
            noun = declension('бренд', 'брендов', 'бренда', num);
            break
        case 'жесткость':
            noun = declension('жесткость', 'жесткостей', 'жесткости', num);
            break
        case 'основа':
            noun = declension('основа', 'основ', 'основы', num);
            break
        case 'материал':
        case 'материалы':
        case 'материал чехла':
            noun = declension('материал', 'материалов', 'материала', num);
            break
        case 'дизайн':
            noun = declension('дизайн', 'дизайнов', 'дизайна', num);
            break
        case 'каркас':
            noun = declension('каркас', 'каркасов', 'каркаса', num);
            break
        case 'цвет':
            noun = declension('цвет', 'цветов', 'цвета', num);
            break
        case 'короб':
            noun = declension('короб', 'коробов', 'короба', num);
            break
        case 'тип':
            noun = declension('тип', 'типов', 'типа', num);
            break
        case 'тип основания':
            noun = declension('тип основания', 'типов оснований', 'типа основания', num);
            break
        case 'тип ламелей':
            noun = declension('тип ламелей', 'типов ламелей', 'типа ламелей', num);
            break
        case 'ламели':
            noun = declension('ламель', 'ламелей', 'ламели', num);
            break
        case 'крепление':
        case 'крепление ламелей':
            noun = declension('крепление', 'креплений', 'крепления', num);
            break
        case 'свойства':
            noun = declension('свойство', 'свойств', 'свойства', num);
            break
        case 'наполнитель':
            noun = declension('наполнитель', 'наполнителей', 'наполнителя', num);
            break
        case 'отделка':
            noun = declension('отделка', 'отделок', 'отделки', num);
            break
        case 'опоры':
            noun = declension('опора', 'опор', 'опоры', num);
            break
        default:
            noun = declension('вариант', 'вариантов', 'варианта', num);
            break
    }

    const result = `выбрано ${num} ${noun ? noun : ''}`

    return result
}

// disable scroll on safari
const $body = document.querySelector('body');
let scrollPosition = 0;

function disableScroll(withTop = true) {
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    if(withTop) {
      $body.style.top = `-${scrollPosition}px`;
    }
    $body.style.width = '100%';
}

function enableScroll() {
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    if (scrollPosition != 0) {
      window.scrollTo(0, scrollPosition);
    }
}

function declination(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2:cases[(number%10<5)?number%10:5] ];
}

function formatPrice(price) {
   return (Math.round(price) + "").replace(/(\d)(?=(\d\d\d)+$)/, "$1 ") + ' руб';
}

// $(window).on('resize', debounce(() => {
//     if(window.innerWidth > size.tablet) enableScroll();
// }, 300));

function getYandexCreditData(sum, elem) {
//   $.get("https://yoomoney.ru/credit/order/ajax/credit-pre-schedule?shopId=" + yandexShopId + "&sum=" + sum, function(data) {
//     let price = (Math.round(data.amount) + "").replace(/(\d)(?=(\d\d\d)+$)/, "$1 ");
//     elem.text(price);
//   });
}

function getDolyameCreditData(sum, elem=false) {
    var paymentType = $('input[name="Order[payment_type]"]:checked').val();
    if(sum < 4 || sum > 30000){
        $('.dolyame_ws').hide();
        if(paymentType == 10){
            $('#order-payment_type .js-payment-radio:first').prop( "checked", true ).change();
        }

        $('#order-payment_type .js-payment-radio').each(function (){
            let val = $(this).val();
            if(val == 10){
                $(this).parent('label').hide();
                if($(this).attr('checked')=='checked'){
                    $('Order[payment_type]').val(9).change();
                }
            }

        });
        return false;
    }else{
        $('.dolyame_ws').show();
        $('#order-payment_type .js-payment-radio').each(function (){
            let val = $(this).val();
            if(val == 10){
                $(this).parent('label').show();
            }
        });
    }
    let week = Math.floor(sum/4);
    if(elem !== false){
        elem.text(Math.round(sum/4));
    }

    let lastWEeek = sum - (week*3);


    $('.dolyame_ws .dolyame_ws__today').html(week + ' руб.');
    $('.dolyame_ws .dolyame_ws__later').html((week * 2 + lastWEeek) + ' руб.');
    $('.dolyame_ws__week1 .dolyame_ws__sum').html(week + ' руб.');
    $('.dolyame_ws__week2 .dolyame_ws__sum').html(week + ' руб.');
    $('.dolyame_ws__week3 .dolyame_ws__sum').html(week + ' руб.');
    $('.dolyame_ws__week4 .dolyame_ws__sum').html(lastWEeek + ' руб.');
}


function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
    vars[key] = value;
  });
  return vars;
}

function supports_history_api() {
    return !!(window.history && history.pushState);
}

function is_object( mixed_var ) {
    return ( mixed_var instanceof Object );
}
function is_array( mixed_var ) {
    return ( mixed_var instanceof Array );
}

/**
 * Determine whether a variable is empty
 * @param {*} mixed_var 
 * @returns 
 */
function empty(mixed_var) {
    return (
        mixed_var === "" 
            || 
        mixed_var === 0
            || 
        mixed_var === "0" 
            || 
        mixed_var === null
            || 
        mixed_var === false
            || 
        (
            is_array(mixed_var) 
                && 
            mixed_var.length === 0
        ) 
    );
}

function copyToClipboard(element) {
  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val($(element).text()).select();
  document.execCommand("copy");
  $temp.remove();
}

function scrollToDiv(element, navHeight) {
    var offset = element.offset();
    var offsetTop = offset.top;
    var totalScroll = offsetTop - navHeight;

    $('body,html').animate({
        scrollTop: totalScroll
    }, 500);
}

function jumpTo(anchor) {
    document.getElementById(anchor).scrollIntoView();
    return false;
}

/**
 * Определяет находится ли элемент в зоне видимости экрана
 */
function inView(target) {
    // Все позиции элемента
    var targetPosition = {
        top: window.pageYOffset + target.get(0).getBoundingClientRect().top,
        left: window.pageXOffset + target.get(0).getBoundingClientRect().left,
        right: window.pageXOffset + target.get(0).getBoundingClientRect().right,
        bottom: window.pageYOffset + target.get(0).getBoundingClientRect().bottom
    },
    // Получаем позиции окна
    windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight
    };

    if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
    targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
    targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
    targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        return true;
    }
    return false;
}

function getConfig(src, extra) {
    const result = Object.assign({
        items: {
            src,
            type: 'inline',
        },
        overflowY: 'scroll',
        fixedContentPos: true,
        closeMarkup: '<button title="Закрыть"  type="button" class="js-popup-close mfp-close"><span>Закрыть</span><svg><use xlink:href="'+iconSVG+'#close"></use></svg></button>',
    }, extra);

    return result;
}

export {
    stringInject,
    matchMediaQuery,
    hasScrollbar,
    changeActiveAddress,
    debounce,
    declension,
    formatText,
    disableScroll,
    enableScroll,
    declination,
    formatPrice,
    getYandexCreditData,
    getDolyameCreditData,
    getUrlVars,
    supports_history_api,
    is_object,
    is_array,
    empty,
    copyToClipboard,
    scrollToDiv,
    jumpTo,
    inView,
    getConfig
};