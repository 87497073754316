import $ from 'jquery';
import 'slick';
import { 
    slickCommonOptions,
} from './../../scripts/config';

/**
 * ReviewSliderV1 widget configs
 */
const reviewSliderV1Semantics = {
    'mainWrapClass': 'review-slider-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'review-slider-v1__prev',
    'controlNextClass': 'review-slider-v1__next',
    'sliderElementClass': 'review-slider-v1__track'
};

/**
 * ReviewSliderV1 initializer
 */
$(function () {

    if ($(`.${reviewSliderV1Semantics.mainWrapClass}`).length === 0) {
        return false;
    }

    window.widgets.reviewSliderV1 = new ReviewSliderV1();

    /**
     * Checking other instances
     */
    $(`.${reviewSliderV1Semantics.mainWrapClass}`).each((index, el) => {
        let isInitialized =
            ($(el).attr(`${reviewSliderV1Semantics.isInitializedAttribute}`) === 'true');
        if (!isInitialized) {
            window.widgets.reviewSliderV1.registerInstance($(el));
        }
    });

});

class ReviewSliderV1 {
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${reviewSliderV1Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions =
            el.data(
                `${reviewSliderV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};


        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: true,
                mobileFirst: true,
                prevArrow: `.${reviewSliderV1Semantics.mainWrapClass}` +
                    `[${reviewSliderV1Semantics.instanceIdAttribute}="` +
                    el.attr(`${reviewSliderV1Semantics.instanceIdAttribute}`) +
                    `"] .${reviewSliderV1Semantics.controlPrevClass}`,
                nextArrow: `.${reviewSliderV1Semantics.mainWrapClass}` +
                    `[${reviewSliderV1Semantics.instanceIdAttribute}="` +
                    el.attr(`${reviewSliderV1Semantics.instanceIdAttribute}`) +
                    `"] .${reviewSliderV1Semantics.controlNextClass}`,
            }
        );
        el.show();
        try {
            let slick = el.find(
                `.${reviewSliderV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);

            el.attr(`${reviewSliderV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = slick;

            this.equalizeSlickSliderHeights(slick);
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    equalizeSlickSliderHeights(slick) {
       // return false;
        let maxHeight = 0;
        // Находим все слайды и определяем максимальную высоту
        slick.find('.review-card-v1').each(function () {
            let slideHeight = $(this).outerHeight();
            maxHeight = Math.max(maxHeight, slideHeight);
        });

        // Устанавливаем все слайды равной максимальной высоте
        slick.find('.slick-slide').each(function () {
            $(this).css('height', maxHeight + 'px');
        });
    }

    reInit(instanceId) {
        // console.log(this.pluginInstances[instanceId].slick());
        this.pluginInstances[instanceId].slick('unslick').slick('init');
    }
}