import $ from 'jquery';

/**
 * LocalityInputV1 widget configs
 */
const localityInputV1Semantics = {
    'mainWrapClass': 'locality-input-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'inputElement': 'locality-input-v1__input',
    'findInPlacesSelector': [
            '.media-card-v1'
        ],
};

/**
 * LocalityInputV1 initializer
 */
$(function () {
    LocalityInputV1.init();
});

class LocalityInputV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${localityInputV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.localityInputV1) {
            window.widgets.localityInputV1 = new LocalityInputV1();
        }

        /**
         * Checking other instances
         */
        $(`.${localityInputV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${localityInputV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.localityInputV1.registerInstance($(el));
                window.widgets.localityInputV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${localityInputV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        try {
            // TODO[dependDetected]: slick plugin
            let object = el.find(`.${localityInputV1Semantics.mainWrapClass}`);
            el.attr(`${localityInputV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        let elements = $(`.${localityInputV1Semantics.mainWrapClass}[${
                localityInputV1Semantics
                        .instanceIdAttribute
        }="${
                el.attr(localityInputV1Semantics
                        .instanceIdAttribute)
        }"]`);

        elements.on(
            'input',
            `.${localityInputV1Semantics.inputElement}`,
            (e) => {
                //Поиск с подсветкой
                e.preventDefault();
                let current = $(e.currentTarget);
                let searchText = current.val().toLowerCase();
                for (let key in localityInputV1Semantics.findInPlacesSelector) {
                    let place = localityInputV1Semantics.findInPlacesSelector[key];
                     $(`${place} mark`).each(function () {
                            $(this).parent().text(
                                 $(this).parent().text()
                            );
                        });

                    $(place).each(function (index, placeElement) {
                        let showPlace = false;
                        if(searchText === '') {
                            $(placeElement).show();
                            return;
                        }
                        $(placeElement).find('*').contents().each(function () {
                            if (this.nodeType === Node.TEXT_NODE) {
                                if (this.textContent.toLowerCase().includes(searchText)) {

                                    let newText = this.textContent.replace(
                                        new RegExp(searchText, 'gi'),
                                        '<mark>$&</mark>'
                                    );

                                    $(this).replaceWith(newText);
                                    showPlace = true;
                                }
                            }
                        });
                        if (showPlace) {
                            $(placeElement).show();
                        } else {
                            $(placeElement).hide();
                        }
                    });
                }
            }
        );
    }

    reInit(instanceId) {
        LocalityInputV1.init();
    }
}