import {MarketingEventTypeEnum} from "../../enums/MarketingEventTypeEnum";
import $ from 'jquery';

class BiMarketingEventRunner {
    constructor() {
        // Объект для хранения активных хендлеров
        this.activeHandlers = {};
    }

    // Метод для регистрации события на элемент
    registerEvent(event) {
        const eventConfig = MarketingEventTypeEnum[event.eventType];
        if (eventConfig && eventConfig.handler) {
            // Проверяем, был ли уже создан хендлер
            let handler = this.activeHandlers[event.eventType];
            if (!handler) {
                // Создаём новый экземпляр хендлера
                handler = new eventConfig.handler();
                this.activeHandlers[event.eventType] = handler;
            }

            // если вдруг js код приходит с сервера заранее
            // сразу добавляем его в кэщ
            if (event.jsCode) {
                // Инициализируем кэш, если его ещё нет
                this.jsCodeCache = this.jsCodeCache || {};
                // Сохраняем jsCode в кэш
                this.jsCodeCache[event.id] = event.jsCode;
            }

            // Регистрируем событие
            handler.register(event, (e) => {
                // Вызов handleEvent с обработкой через промис и возращаем обязательно промис
                return this.handleEvent(e, event, handler)
                    .then(() => {
                        // ну и в лог можно добавить на всякий случай.
                        // console.log('Маркетинговое событие успешно завершено');
                    })
                    .catch((error) => {
                        console.error('Ошибка выполнения маркетингового события:', error);
                    });
            });
        } else {
            console.warn(`Нет обработчика для события: ${event.eventType}`);
        }
    }


    // публичный общий метод для получения всех событий и регистрации их
    registerAllEvents(events) {
        events.forEach((event) => {
            this.registerEvent(event);
        });
    }

    // Реализация выполнения события
    handleEvent(e, event, handler) {
        // Кэш для хранения jsCode по id событий
        this.jsCodeCache = this.jsCodeCache || {};

        return new Promise((resolve, reject) => {

            // Проверяем, есть ли код в кэше для вызываемого события
            const cachedJsCode = this.jsCodeCache[event.id];

            if (cachedJsCode) {
                // Если код уже закэширован, выполняем его сразу
                // console.log('Используем кэшированный jsCode для события', event.id);
                handler.execute(event, cachedJsCode);
                resolve();
                return;
            }

            $.ajax({
                method: 'POST',
                url: `/bi/event/get-event`,
                headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content")},
                data: 'eventId=' + event.id,
                success: (response) => {
                    const {jsCode} = response;

                    // добавляем в кэш код для повтороного использования
                    this.jsCodeCache[event.id] = jsCode;

                    // и выполняем jsCode для зарегистрированного элемента
                    handler.execute(event, jsCode);
                    resolve();
                },
                error: (xhr, status, error) => {
                    console.error('Ошибка при получении jsCode:', error);
                    reject(error);
                }
            });
        });
    }
}

export const biMarketingEventRunner = new BiMarketingEventRunner();