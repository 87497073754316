import $ from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar/dist/perfect-scrollbar.esm';

const modalSelectLocationWindowV1Semantics = {
    'citiesSearchDropdownSelector': '.js-cities-search-dropdown',
};

$(function () {
    let modalSelectLocationWindowV1 = new ModalSelectLocationWindowV1();
    modalSelectLocationWindowV1.subscribe();
});

class ModalSelectLocationWindowV1 {

    citySelectInit() {
        $('.js-city-row, .js-city-select').on('click', function(e) {
            e.preventDefault();
            const url = new URL(window.location.href);
            url.host = $(this).attr('data-domain');
            window.location.replace(url.href);
        });
    }

    subscribe() {

        var self = this;
        
        const cdScrollbar = new PerfectScrollbar($(
            modalSelectLocationWindowV1Semantics.citiesSearchDropdownSelector
        )[0]);

        $('.js-cities-search').on('focus', function(e) {
            const $dropdown = $(
                modalSelectLocationWindowV1Semantics.citiesSearchDropdownSelector
            );

            if ($(this).val() != '') {
            $dropdown.show();
            }
        });
        $('body').on('click', function(e) {
            if ($(e.target).closest('.js-cities-search-form').length < 1) {
                $(
                    modalSelectLocationWindowV1Semantics.citiesSearchDropdownSelector
                ).hide();
            }
        });

        $('.js-cities-search').on('keyup', function(e) {
            let value = $(this).val().toLowerCase();
            const $form = $(this).closest('form');
            const $dropdown = $form.find(
                modalSelectLocationWindowV1Semantics.citiesSearchDropdownSelector
            );


            // console.log(e.keyCode);
            if (e.keyCode == 40) {
                if ($dropdown.find('a._active').length < 1) {
                    $dropdown.find('a:first-child').addClass('_active');
                } else {
                    $dropdown.find('a._active').removeClass('_active').next().addClass('_active');
                    // $dropdown.scrollTop = $dropdown.find('a._active').offset().top;
                    // $dropdown.animate({ scrollTop: $dropdown.find('a._active').offset().top }, 1000);
                }
                return false;
            } else if (e.keyCode == 38) {
                if ($dropdown.find('a._active').length < 1) {
                    $dropdown.find('a:last-child').addClass('_active');
                } else {
                    $dropdown.find('a._active').removeClass('_active').prev().addClass('_active');
                    // $dropdown.scrollTop = $dropdown.find('a._active').offset().top;
                    // $dropdown.animate({ scrollTop: $dropdown.find('a._active').offset().top }, 1000);
                }
                return false;
            }

            $dropdown.html('');
            $dropdown.show();
            //mvp
            $('.cities-popup__content').find('.modal-window-region-switcher-v2__wrap ul li a').each(function() {
            let text = $(this).text();
            if (text.toLowerCase().indexOf(value) > -1) {
                $dropdown.append(`
                <a href="${$(this).attr('href')}" class="js-city-select" 
                data-domain="${$(this).attr('data-domain')}" 
                data-id="${$(this).attr('data-id')}">${text}</a>
                `);
            }
            });
            self.citySelectInit();

            if ($dropdown.find('a').length < 1) {
            $dropdown.append('<div class="_nothing">Ничего не найдено</div>');
            }

            cdScrollbar.update();
        });

        $('.js-cities-search-form').on('submit', function(e) {
            const $dropdown = $(
                modalSelectLocationWindowV1Semantics.citiesSearchDropdownSelector
            );
            let cityId = false;
            if ($dropdown.find('a._active').length > 0) {
            cityId = $dropdown.find('a._active').attr('data-id');
            } else if($dropdown.find('a').length > 0) {
            cityId = $dropdown.find('a:first-child').attr('data-id');
            }
            // sendChangeSityRequest(cityId, false);

            return false;
        });

    }
}
