import $ from 'jquery';

const ratingStarVoteV1Semantics = {
    mainWrapClass: 'rating-star-vote-v1',
    isInitializedAttribute: 'data-is-initialized',
    instanceIdAttribute: 'data-instance-id',
    elementClass: 'rating-star-vote-v1__star-item',
    activeElementClass: 'rating-star-vote-v1__item-active',
    selectedClass: 'rating-star-vote-v1__item-selected',
    elementInput: 'rating-star-vote-v1__data',
}

$(function () {

    let roots = $(`.${ratingStarVoteV1Semantics.mainWrapClass}`);
    if (roots.length === 0) {
        return false;
    }
    let ratingStarVoteV1 = new RatingStarVoteV1();
    /**
     * Checking other instances
     */
    roots.each((index, el) => {
        let isInitialized =
            ($(el).attr(`${ratingStarVoteV1Semantics.isInitializedAttribute}`) === 'true');

        if (!isInitialized) {
            ratingStarVoteV1.registerInstance($(el));
            ratingStarVoteV1.registerListeners($(el));
        }
    });
})

class RatingStarVoteV1 {

    constructor() {
        this.pluginInstances = {};
    }

    registerInstance(el) {

        let instanceId =
            el.attr(`${ratingStarVoteV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${ratingStarVoteV1Semantics.mainWrapClass}`);
            el.attr(`${ratingStarVoteV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el)
    {
        let elements = $(`.${ratingStarVoteV1Semantics.mainWrapClass}[${
            ratingStarVoteV1Semantics
                .instanceIdAttribute
        }="${
            el.attr(ratingStarVoteV1Semantics
                .instanceIdAttribute)
        }"]`);

        elements.on('mouseleave', function (e) {
            let current = $(e.currentTarget);
            $(current).find(`.${ratingStarVoteV1Semantics.activeElementClass}`).removeClass(ratingStarVoteV1Semantics.activeElementClass);
        });

        elements.on('click', `.${ratingStarVoteV1Semantics.elementClass}`, function(e) {
                let el = $(e.currentTarget);
                el.addClass(ratingStarVoteV1Semantics.selectedClass);
                el.siblings(`.${ratingStarVoteV1Semantics.elementInput}`).val(el.attr('data-num'));
                RatingStarVoteV1.selectRate(el, ratingStarVoteV1Semantics.selectedClass);
            }
        );

        elements.on('mouseover', `.${ratingStarVoteV1Semantics.elementClass}`,function(e){
            RatingStarVoteV1.selectRate($(e.currentTarget) , ratingStarVoteV1Semantics.activeElementClass);
        });
    }

    static selectRate(el, className) {
        el.prevAll().addClass(className);
        el.nextAll().removeClass(className);
    }
}

export default RatingStarVoteV1;