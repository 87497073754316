import $ from 'jquery';

/**
 * QuestionnaireOptionItemV1 widget configs
 */
const questionnaireOptionItemV1Semantics = {
    'mainWrapClass': 'questionnaire-option-item-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'actionElement': '.questionnaire-option-item-v1__label',
    'inputElement': '.questionnaire-option-item-v1__radio',
    'nextStageIdAttribute': 'data-next',
    'callerClassAttribute': 'data-caller-class',
    'checkedAttribute': 'data-is-checked',
};

/**
 * QuestionnaireOptionItemV1 initializer
 */
$(function () {
    QuestionnaireOptionItemV1.init();
});

class QuestionnaireOptionItemV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${questionnaireOptionItemV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.questionnaireOptionItemV1) {
            window.widgets.questionnaireOptionItemV1 = new QuestionnaireOptionItemV1();
        }

        /**
         * Checking other instances
         */
        $(`.${questionnaireOptionItemV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${questionnaireOptionItemV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.questionnaireOptionItemV1.registerInstance($(el));
                window.widgets.questionnaireOptionItemV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${questionnaireOptionItemV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            // TODO[dependDetected]: slick plugin
            let object = el.find(`.${questionnaireOptionItemV1Semantics.mainWrapClass}`);
            el.attr(`${questionnaireOptionItemV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        el.on('click', `${questionnaireOptionItemV1Semantics.actionElement}`, (event) => {
            let e = $(event.currentTarget);
            let inputElement = el.find(questionnaireOptionItemV1Semantics.inputElement);
            el.attr(questionnaireOptionItemV1Semantics.checkedAttribute, true)
                .siblings().attr(questionnaireOptionItemV1Semantics.checkedAttribute, false);
            inputElement.prop('checked', true);
            let nextStageId = inputElement.attr(questionnaireOptionItemV1Semantics.nextStageIdAttribute)
            if (nextStageId !== undefined) {
                window.widgets.questionnaireEntityV1.nextStageFromOptionItem(e, nextStageId)
            } else {
                let callerClass = e.closest(`.${questionnaireOptionItemV1Semantics.mainWrapClass}`)
                    .attr(`${questionnaireOptionItemV1Semantics.callerClassAttribute}`)

                if (typeof window.widgets === 'object' &&
                    typeof window.widgets[callerClass] === 'object' &&
                    typeof window.widgets[callerClass].validate === 'function') {
                    window.widgets[callerClass].validate(e, nextStageId);
                } else {
                    throw new Error('Класс ' + callerClass + ' Метод validate не найден');
                }
            }
        });
    }

    showStage(stageId) {

    }

    backStage() {

    }

    reInit(instanceId) {
        QuestionnaireOptionItemV1.init();
    }
}