export class BaseMarketingEventHandler {
    // Метод для регистрации события на элемент
    // обязательно должен быть реализован в наследниках
    register(element) {
        throw new Error("Метод register должен быть реализован в наследниках");
    }

    // Метод для выполнения события может быть перезаписан в наследниках
    execute(event, jsCode) {
        try {
            eval(jsCode);
        } catch (error) {
            console.error('Ошибка выполнения jsCode:', error);
        }
    }
}