import $ from 'jquery';
import {CompareModule} from "../../services/compare/Compare";
const addToCompareV1Semantics = {
    'initiatorElementSelector': '[add-to-compare-v1]',
    'isInCompareAttributeName': 'data-add-to-compare-v1-is-in-compare',
    'productTypeIdAttributeName': 'data-add-to-compare-v1-product-type-id',
    'idAttributeName': 'data-add-to-compare-v1-id',
    'virtualModifierIdAttributeName': 'data-add-to-compare-v1-virtual-modifier-id',
};

$(function () {
    let addToCompareV1 = new AddToCompareV1();
    addToCompareV1.subscribe();
    addToCompareV1.checkSelected();
    window.widgets.addToCompareV1 = addToCompareV1;
});

class AddToCompareV1 {
    subscribe() {
        let self = this;
        $(document).on(
            'click',
            addToCompareV1Semantics.initiatorElementSelector,
            function (e) {
                e.preventDefault();
                let initiatorElement = $(e.currentTarget);

                CompareModule.list.addOrChange(
                    initiatorElement.attr(
                        addToCompareV1Semantics
                            .productTypeIdAttributeName
                    ),
                    initiatorElement.attr(
                        addToCompareV1Semantics
                            .idAttributeName
                    ),
                    initiatorElement.attr(
                        addToCompareV1Semantics
                            .virtualModifierIdAttributeName
                    ),
                    function () {
                        let selector = `[${addToCompareV1Semantics.idAttributeName}="${initiatorElement.attr(addToCompareV1Semantics.idAttributeName)}"]`;
                        selector += `[${addToCompareV1Semantics.productTypeIdAttributeName}="${initiatorElement.attr(addToCompareV1Semantics.productTypeIdAttributeName)}"]`;
                        selector += `[${addToCompareV1Semantics.virtualModifierIdAttributeName}="${initiatorElement.attr(addToCompareV1Semantics.virtualModifierIdAttributeName)}"]`
                        self.toggleElement(
                            $(selector)
                        )
                    }
                );
            }
        );
    }

    checkSelected() {
        if (!(CompareModule?.list?.getProductSet?.() instanceof Object)) {
            return;
        }
        let productSet = CompareModule.list.getProductSet().products;
        for (let key in productSet) {

            let selector = `[${addToCompareV1Semantics.idAttributeName}="${productSet[key]['id']}"]`;

            if (productSet[key]['productTypeId']) {
                selector += `[${addToCompareV1Semantics.productTypeIdAttributeName}="${productSet[key]['productTypeId']}"]`;
            }

            if ('virtualModifier' in productSet[key]) {
                let virtualModifierValue = productSet[key]['virtualModifier'] ? productSet[key]['virtualModifier'] : '';
                selector += `[${addToCompareV1Semantics.virtualModifierIdAttributeName}="${virtualModifierValue}"]`;
            } else {
                selector += `:not([${addToCompareV1Semantics.virtualModifierIdAttributeName}]), [${addToCompareV1Semantics.virtualModifierIdAttributeName}=""]`;
            }

            let foundElement = $(selector);
            if (foundElement.length) {
                this.toggleElement(foundElement, true);
            }
        }
    }

    //Добавляю второй параметр с указанием состояние, если параметр не передан
    //то будет происходить просто переключения с текущего состояния на противоположное
    toggleElement(elements, state) {
        $.each(elements, (key, element) => {
            let $element = $(element);
            let toggleVal;
            if (typeof state !== 'undefined') {
                toggleVal = state ? "1" : "0";
            } else {
                toggleVal = $element.attr(addToCompareV1Semantics.isInCompareAttributeName) === "1" ? "0" : "1";
            }

            $element.attr(addToCompareV1Semantics.isInCompareAttributeName, toggleVal);
        });
    }
}