import ErrorCodes from "./ErrorCodes";

const errorTemplates = {
    [ErrorCodes.REQUIRED]: 'Значение должно быть заполнено',
    [ErrorCodes.WRONG_EMAIL]: 'Значение не является валидным email-адресом',
    [ErrorCodes.NO_RUSSIAN_MOBILE_PHONE]: 'Значение должно быть в формате +7 (9XX) XXX-XX-XX',
    [ErrorCodes.NO_RUSSIAN_PHONE]: 'Значение должно быть в формате +7 (XXX) XXX-XX-XX',
};

const Validator = {};

Validator.generateError = function(code, mapReplace = {}) {
    return (errorTemplates[code] ?? '').mapReplace(mapReplace);
};

export default Validator;