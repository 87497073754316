import {BaseMarketingEventHandler} from '../BaseMarketingEventHandler.js';
import $ from 'jquery';

const EVENT_NAMESPACE = 'click.biMarketingEvent';

export class ClickByHandler extends BaseMarketingEventHandler {
    // Реализация регистрации события
    register(event, eventCallback) {
        const element = $(event.jsSelector);
        if (!element.length) {
            console.warn('Элемент не найден, пропускаем событие', event);
            return;
        }
        // Добавляем флаг, чтобы избежать рекурсии
        let isProcessing = false;
        // Регистрируем событие на клик по элементу
        // надо добавить namespace чтобы событие не срабатывало не сколько раз

        const eventHandler = function (e) {
            if (isProcessing) {
                // Предотвращаем повторное выполнение
                return;
            }
            isProcessing = true;
            // оставнавливаем все события
            e.preventDefault();
            e.stopImmediatePropagation();

            // Выполняем маркетинговый код
            eventCallback(e).then(() => {
                if (e.target.tagName === 'A' && e.target.href) {
                    window.location.href = e.target.href;
                } else {
                    e.target.click();
                }
            }).catch(error => {
                console.error('Ошибка выполнения маркетингового события:', error);
                isProcessing = false;
            }).finally(() => {
                // так же сброс флага после завершения.
                isProcessing = false;
            });
        };
        // по
        // if (!element.data('event-registered')) {
        //jquery не поддерживает capture события поэтому чистый js
        if (element[0]) {
            element[0].addEventListener('click', eventHandler, {capture: true});
        }
        //   element.data('event-registered', true)
        //}
    }


    execute(event, jsCode) {
        try {
            eval(jsCode);
        } catch (error) {
            console.error('Ошибка выполнения jsCode:', error);
        }
    }
}