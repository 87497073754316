import $ from 'jquery';

class CssVars {
    constructor() {
        //elems
        this.elems = {};
        this.elems.$header = $('.js-sticky-header');
        this.elems.$footer = $('.js-sticky-footer');
        this.elems.$filterSort = $('.js-filter-sort');
        this.elems.$smoothHeight = $('.js-smooth-height');

        this.set('--calc-height', 'auto');
        this.update();
        this.addListeners();
    }

    update() {
        // alert('update');
        const {$header, $footer, $smoothHeight, $filterSort} = this.elems;
        this.set('--header-height', $header.outerHeight());

        let hDH = 0;
        if($('.head-discount').length > 0) {
          hDH = $('.head-discount').outerHeight();
        }
        this.set('--header-discount-height', $header.outerHeight() + hDH + $('.site-header__bottom').outerHeight());

        if(typeof($footer) != "undefined" && $footer !== null) {
          this.set('--footer-height', $footer.outerHeight() - 1);
        } else {
          this.set('--footer-height', 0.01);
        }

        let sortHeight = 0;
        /*if ($filterSort.length > 0) {
          sortHeight = $filterSort.outerHeight();
        }*/
        // this.set('--footer-height', 1);
        this.set('--sort-height', sortHeight);
        this.set('--vh', window.innerHeight * 0.01);

        // smooth height
        $smoothHeight.each(function() {
            const height = $(this).children().outerHeight(true);
            if(this.style.getPropertyValue('--calc-height') !== height) {
                this.style.setProperty('--calc-height', height + 'px');
            }
        });
    }

    get(name) {
        const value = getComputedStyle(document.documentElement).getPropertyValue(name);
        return parseInt(value, 10);
    }

    set(name, value) {
        if(!value) return
        if($.isNumeric(value)) {
            document.documentElement.style.setProperty(name, value + 'px');
        } else {
            document.documentElement.style.setProperty(name, value);
        }
    }

    addListeners() {
        $(window).on('resize', () => {
            this.update();
        });
    }
}

export {
    CssVars,
};
