import $ from 'jquery';

/**
 * SeoTextBlockV1 widget configs
 */
const seoTextBlockV1Semantics = {
    'mainWrapClass': 'seo-text-block-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'switcherClass': 'seo-text-block__toogler', 
    'textContainerClass': 'seo-text-block__container',
    'textWrapperClass': 'seo-text-block__text-wrapper',
};

/**
 * SeoTextBlockV1 initializer
 */
$(function() {
    SeoTextBlockV1.init();
});

class SeoTextBlockV1
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    static init(reInit = false) {
        let instances = $(`.${seoTextBlockV1Semantics.mainWrapClass}`);
        if (instances.length === 0) {
            return false;
        }

        let seoTextBlockV1 = new SeoTextBlockV1();

        /**
         * Checking other instances
         */
        instances.each((index, el) => {
            let isInitialized =
                ($(el).attr(`${seoTextBlockV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized || reInit) {
                seoTextBlockV1.registerInstance($(el));
                seoTextBlockV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId = 
            el.attr(`${seoTextBlockV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            el.attr(`${seoTextBlockV1Semantics.isInitializedAttribute}`, true);
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    /**
     * Register listeners
     * @param jQuery element
     * @throws
     */
    registerListeners(el) {
        let self = this;
        $(el).on(
            'click', 
            `.${seoTextBlockV1Semantics.switcherClass}`, 
            function (e) {
                var c = $(this).parents(
                    `.${seoTextBlockV1Semantics.textContainerClass}`
                ).find(
                    `.${seoTextBlockV1Semantics.textWrapperClass}`
                );
                c.toggleClass('opened');
                if (c.hasClass('opened')) {
                    $(this).html('Свернуть');
                } else {
                    $(this).html('Показать целиком');
                }
            }
        );
    }

    reInit(instanceId) {
        SeoTextBlockV1.init(true);
    }
}