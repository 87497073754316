import $ from 'jquery';
import Cookies from 'js-cookie';
import CsrfToken from '../csrf/Csrf';
import {ProductTypeEnum} from '../../enums/ProductTypeEnum';

const CartModule = {};

CartModule.Cart = class
{
    isLazyInit = false;

    /**
     * @var {CartModule.CartProductSet}
     */
    productSet;

    /**
     * @var {CartModule.CounterTracker}
     */
    counterTracker;

    /**
     * @param {bool} isLazyInit 
     */
    constructor(isLazyInit = false) {
        let self = this;
        self.isLazyInit = isLazyInit;
        if (self.isLazyInit === false) {
            self.initComponent();
        }
    }
    updateCounterTracker() {
        this.counterTracker.update();
    }
    initComponent() {

        let self = this;
        self.productSet = new CartModule.CartProductSet();
        self.counterTracker = new CartModule.CounterTracker(
            self
        );

        let rawCart = Cookies.get('cart') ? JSON.parse(Cookies.get('cart')) : [];
        self.fillElements(rawCart);

    }

    getProductSet() {
        return this.productSet;
    }

    /**
     * @param {array} rawElements 
     */
    fillElements(rawElements) {

        let self = this;

        // clear old values
        self.productSet.products = [];

        for (let key in rawElements) {
            switch (rawElements[key].productTypeId) {
                case ProductTypeEnum.card:
                    self.productSet.products.push(
                        new CartModule.CardCartProduct(
                            rawElements[key]
                        )
                    );
                break;
                case ProductTypeEnum.realModifier:
                    self.productSet.products.push(
                        new CartModule.RealModifierCartProduct(
                            rawElements[key]
                        )
                    );
                break;
                default:
                  //  console.log('unknownProductType', rawElements[key]);
                break;
            }
        }
    }

    /**
     * @param {int} productTypeId 
     * @param {int} id 
     * @param {int} count 
     * @param {int|null} virtualModifierId 
     */
    addOrChange(
        productTypeId,
        id,
        count,
        virtualModifierId = null,
        callback= null,
    )
    {
        let self = this;
        let data = {
            'productTypeId': productTypeId,
            'id': id,
            'count': count,
            'virtualModifierId': virtualModifierId,
        };
        data[CsrfToken.name] = CsrfToken.value;

        $.post({
            url: '/cart/add-or-change',
            data: data,
            success: function(rawElements) {
                self.fillElements(rawElements);
                self.counterTracker.update();
                if (typeof callback === 'function') {
                    callback();
                }
            },
            error: function(xhr, status, error) {
                console.error("Error occurred: " + status + ", " + error);
            }
        })
    }

    /**
     * @return {int}
     */
    getSummaryCount() {
        return this.productSet.getSummaryCount();
    }

}

CartModule.CounterTracker = class {

    /**
     * @var {CartModule.Cart}
     */
    cart;

    trackerSelector = [
        {
            counterSelector: '.site-header__indicators-link._cart .site-header__indicators-count span',
            wrapSelector: '.site-header__indicators-link._cart .site-header__indicators-count'
        },
        {
            counterSelector: '.sticky-footer-v1__cart  .site-header__indicators-count span',
            wrapSelector: '.sticky-footer-v1__cart .site-header__indicators-count'
        }
    ];

    constructor(cart) {
        let self = this;
        self.cart = cart;
    }

    update() {

        let self = this;
        for (let key in self.trackerSelector) {
            let counterSelector = self.trackerSelector[key].counterSelector;
            let wrapSelector = self.trackerSelector[key].wrapSelector;

            if (self.cart.getSummaryCount() > 0) {
                $(wrapSelector).removeClass('_hide');
                $(counterSelector).empty().html(self.cart.getSummaryCount());
            } else {
                $(wrapSelector).addClass('_hide');
            }

        }

    }

}

CartModule.CartProductSet = class {

    /**
     * @var {(CartModule.CardCartProduct|CartModule.RealModifierCartProduct)[]}
     */
    products = [];
    
    /**
     * @return {int}
     */
    getSummaryCount() {
        let count = 0;
        this.products.forEach(product => {
            count += product.count;
        });
        return count;
    }

}

CartModule.BaseCartProduct = class {
    id;
    productTypeId;
    count;
}

CartModule.CardCartProduct = class {
    productTypeId = ProductTypeEnum.card;
    
    constructor(cartElement) {
        let self = this;
        self.id = cartElement.id;
        self.count = cartElement.count;
    }
}

CartModule.RealModifierCartProduct = class {

    productTypeId = ProductTypeEnum.realModifier;

    /**
     * @var {CartModule.VirtualModifierCartProduct|null}
     */
    virtualModifier = null;

    constructor(cartElement) {
        let self = this;
        self.id = cartElement.id;
        self.count = cartElement.count;
        if (cartElement.virtualModifierId) {
            self.virtualModifier = new CartModule.VirtualModifierCartProduct({
                id: cartElement.virtualModifierId
            });
        }
    }
}

CartModule.VirtualModifierCartProduct = class {
    productTypeId = ProductTypeEnum.virtualModifier;

    constructor(cartElement) {
        let self = this;
        self.id = cartElement.id;
    }
}

CartModule.cart = new CartModule.Cart();

export {
    CartModule
};