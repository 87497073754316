import $ from 'jquery';

$(() => {
    initSearch();
});

function initSearch() {
    let ps

    $('.js-search-input').on('focus', function() {
        $(this).closest('.js-search-form').addClass('_focused');
        if(!ps) {
          if($(this).closest('.js-search-form').find('.js-search-good-list').length > 0) {
            const goodList = $(this)
                .closest('.js-search-form')
                .find('.js-search-good-list')[0];

            setTimeout(() => {
                ps = new PerfectScrollbar(goodList, {wheelPropagation: false});
            }, 0);
          }
        } else {
            ps.update();
        }
    });

    $(document).on('click', function(e) {
        const $target = $(e.target);
        if($target.closest('.js-search-form').length) return

        $('.js-search-form').removeClass('_focused');
    });

    $('.js-searchform-close').on('click',function(e) {
        $('.js-search-form').removeClass('_focused');
        e.preventDefault();
        $('.js-search-form input').val('');
    });

    $('.js-search-input').on('input', function() {
        const value = this.value;
        const $this = $(this);
        // запрос
        // if(!value) {
        //   return;
        // }

        $.get('/site/suggest', {'term': $(this).val()}, function (data) {
            //console.log(data);
            if (data['status']) {

              $('.search-dropdown').html(data['output']);

              if (typeof compareIds !== 'undefined'){
                  compareArray(compareIds);
              }
              if (typeof wishListIds !== 'undefined'){
                  favoriteArray(wishListIds);
              }

              if($this.closest('.js-search-form').find('.js-search-good-list').length > 0) {
                const goodList = $this.closest('.js-search-form').find('.js-search-good-list')[0];
                setTimeout(() => {
                    ps = new PerfectScrollbar(goodList, {wheelPropagation: false});
                }, 0);
              }

              if (typeof compareIds !== 'undefined'){
                  compareArray(compareIds);
              }
              if (typeof wishListIds !== 'undefined'){
                  favoriteArray(wishListIds);
              }


            }
        });



    });

    $('.js-search-form').on('submit', function(e) {
      let value = $(this).find('.js-search-input').val();
      if(value == '') {
        e.preventDefault();
        return;
      }
    });

}

export {
    initSearch
};
