import $ from 'jquery';

/**
 * ModalWindowAddToCartV1 widget configs
 */
const modalWindowAddToCartV1Semantics = {
    'mainWrapClass': 'modal-window-add-to-cart-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'containerClass' : 'modal-window-add-to-cart-v1__wrap_container',
    'requestEndPointUrl': 'cart-data/item',
    'preloaderClass' : 'modal-window-add-to-cart-v1__preloader',
};


$(function () {
    ModalWindowAddToCartV1.init();
})

class ModalWindowAddToCartV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${modalWindowAddToCartV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.modalWindowAddToCartV1) {
            window.widgets.modalWindowAddToCartV1 = new ModalWindowAddToCartV1();
        }

        /**
         * Checking other instances
         */
        $(`.${modalWindowAddToCartV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${modalWindowAddToCartV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.modalWindowAddToCartV1.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${modalWindowAddToCartV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${modalWindowAddToCartV1Semantics.mainWrapClass}`);
            el.attr(`${modalWindowAddToCartV1Semantics.isInitializedAttribute}`, true);
            window.widgets.modalWindowAddToCartV1.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    onOpenPopup(
        productTypeId,
        id,
        virtualModifierId
    ) {
        let params = {
            'productTypeId': productTypeId,
            'id': id,
            'virtualModifierId': virtualModifierId
        };
        $.ajax({
            method: 'GET',
            url: modalWindowAddToCartV1Semantics.requestEndPointUrl,
            dataType: 'html',
            data: params,
            beforeSend: function () {
                $(`.${modalWindowAddToCartV1Semantics.containerClass}`).html(
                    ''
                );
                 $(`.${modalWindowAddToCartV1Semantics.preloaderClass}`).show();
            },
            success: function (data) {
                $(`.${modalWindowAddToCartV1Semantics.preloaderClass}`).hide();
                $(`.${modalWindowAddToCartV1Semantics.containerClass}`).html(
                   data
                );
            },
            error: function (xhr, status, error) {
               // console.error('Ошибка при загрузке информации о товаре событий:', error);
            }
        })
    }
}