import {
    matchMediaQuery,
} from './helpers';
import $ from 'jquery';
import 'magnific-popup/dist/jquery.magnific-popup';

let popupCheck = new Map();

$(() => {
    initPopup();
})

function setPopupConfig(selector, config) {
    var modalEl = $(selector);
    var modelName = $(selector).data('form-model-name') ?? null;
    
    if (!modalEl.length) {
        return false;
    }

    for (var inputName in config) {
        var normalizedInputName = inputName;
        if (modelName !== null) {
            normalizedInputName = modelName + '[' + inputName + ']';
        }

        if (!($(modalEl).find('input[name="' + normalizedInputName + '"]').length)) {
            continue;
        }

        $(modalEl)
            .find('input[name="' + normalizedInputName + '"]')
            .val(config[inputName]);
    }

    return true;
}

function initPopup($body) {
    
    if (!$body) {
        $body = $('body');
    }

    // событие открытия окна
    $(document).on(
        'click', 
        '.js-open-popup',
        function(e) {
            e.preventDefault();
            // continue the process of the popup location switcher 
            // for 2nd level activator of the screens < 1024px
            if ($(this).hasClass('js-2nd-level-activator') 
                && matchMediaQuery('(max-width: 1023px)')
                && $(this).data('popup') === 'js-cities-popup') {
                return false;
            }
            var popup = $(this).data('popup');
            var popupConfig = $(this).data('popup-config');
            var selector = '.' + popup;

            if (popupConfig instanceof Object) {
                setPopupConfig(selector, popupConfig);
            }

            // TODO[task]: fast impl for makeup, create component
            var configPart = {
                callbacks: {
                    open: function open() {
                        if (this.content.height() > this.container.height()) {
                            this.content.addClass('_scrollable');
                        } else {
                            this.content.removeClass('_scrollable');
                        }
                        popupCheck.set(popup, true);
                    },
                    close: function close() {
                        popupCheck.delete(popup);
                    }
                }
            };
            if (popup === 'js-interior-popup') {
                configPart['closeMarkup'] = `<div class="interior-popup-v1__close">
                    <svg class="interior-popup-v1__close-icon" width="16" height="16">
                        <use xlink:href="${iconSVG}#close-icon"></use>
                    </svg>
                </div>`;
                configPart['closeBtnInside'] = true;
            }
            var config = initPopup.prototype.getConfig(selector, configPart);
            // \TODO[task]: fast impl for makeup, create component

            $.magnificPopup.open(config);
            // $(this).magnificPopup(config);
        }
    );

    // инициализация окон
    $('.js-open-popup', $body).each(function () {
        // continue the process of the popup location switcher 
        // for 2nd level activator of the screens < 1024px
        if ($(this).hasClass('js-2nd-level-activator')
            && matchMediaQuery('(max-width: 1023px)')
            && $(this).data('popup') === 'js-cities-popup') {
            return false;
        }
        var popup = $(this).data('popup');
        var selector = '.' + popup;
        
        // TODO[task]: fast impl for makeup, create component
        var configPart = {
            callbacks: {
                open: function open() {
                    if (this.content.height() > this.container.height()) {
                        this.content.addClass('_scrollable');
                    } else {
                        this.content.removeClass('_scrollable');
                    }
                    popupCheck.set(popup, true);
                },
                close: function close() {
                    popupCheck.delete(popup);
                }
            }
        };
        if (popup === 'js-interior-popup') {
            configPart['closeMarkup'] = `<div class="interior-popup-v1__close">
                <svg class="interior-popup-v1__close-icon" width="16" height="16">
                    <use xlink:href="${iconSVG}#close-icon"></use>
                </svg>
            </div>`;
            configPart['closeBtnInside'] = true;
        }
        var config = initPopup.prototype.getConfig(selector, configPart);
        // \TODO[task]: fast impl for makeup, create component

        $(this).magnificPopup(config);
    });

    $(document).on('click', '.js-popup-close',  function(e) {
        $.magnificPopup.close();
        if($(this).hasClass('cities-popup__close')){
            if (typeof confirmCity === "function") {
                confirmCity(e);
            }
        }
    });
}

initPopup.prototype.getConfig = function(src, extra) {
    const result = Object.assign({
        items: {
            src,
            type: 'inline',
        },
        overflowY: 'scroll',
        fixedContentPos: true,
        closeMarkup: '<button title="Закрыть"  type="button" class="js-popup-close mfp-close"><span>Закрыть</span><svg><use xlink:href="'+iconSVG+'#close"></use></svg></button>',
    }, extra);

    return result;
}

export {
    initPopup
};
