import { 
    stringInject,
    matchMediaQuery
} from '../../scripts/helpers';
import $ from 'jquery';
import {
    catalogSubmenu3rdDropdownItemTemplate,
    catalogSubmenu3rdDropdownListTemplate,
    catalogSubmenu3rdMainItemTemplate,
    catalogSubmenu3rdMainListTemplate,
    catalogSubmenu3rdMainWrapTemplate,
    catalogSubmenu2dMainItemTemplate,
    catalogSubmenu2ndMainListTemplate,
    catalogSubmenu2ndWrapTemplate,
    locationSwitcherItemTemplate,
    locationSwitcherListTemplate,
    locationSwitcherSearchTemplate,
    locationSwitcherWrapTemplate
} from '../../scripts/htmlTemplates';
import '../../plugins/btsListFilter';


/**
 * MainSubMenuV1 widget configs
 */
const mainSubMenuV1Semantics = {
    'mainWrapClass': 'main-sub-menu-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'subMenuItemClass': 'submenu__main-menu-item',
    'subMenuItemActiveClass': 'submenu__main-menu-item_active',
    'subMenuWrapperActiveClass': 'submenu__submenu-wrapper_active',
    'subMenuWrapperElement' : 'submenu__submenu-wrapper',
    'subMenuWrapperParent' : 'submenu__wrapper',
    'subMenuWrapperBlock': 'main-sub-menu-v1__column-wrapper',
};

/**
 * MainSubMenuV1 initializer
 */
$(function () {
    MainSubMenuV1.init();
});

class MainSubMenuV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${mainSubMenuV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.mainSubMenuV1) {
            window.widgets.mainSubMenuV1 = new MainSubMenuV1();
        }

        /**
         * Checking other instances
         */
        $(`.${mainSubMenuV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${mainSubMenuV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.mainSubMenuV1.registerInstance($(el));
                window.widgets.mainSubMenuV1.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${mainSubMenuV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            // TODO[dependDetected]: slick plugin
            let object = el.find(`.${mainSubMenuV1Semantics.mainWrapClass}`);
            el.attr(`${mainSubMenuV1Semantics.isInitializedAttribute}`, true);
            window.widgets.mainSubMenuV1.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        let elements = $(`.${mainSubMenuV1Semantics.mainWrapClass}[${
                mainSubMenuV1Semantics
                        .instanceIdAttribute
        }="${
                el.attr(mainSubMenuV1Semantics
                        .instanceIdAttribute)
        }"]`);

        elements.on(
            'mouseenter',
            `.${mainSubMenuV1Semantics.subMenuItemClass}`,
            (e) => {
                e.preventDefault();
                let current = $(e.currentTarget);
                let hstId = current.addClass(`.${mainSubMenuV1Semantics.subMenuItemActiveClass}`).data('hst-id');

                let subTab = elements.find(`.${mainSubMenuV1Semantics.subMenuWrapperBlock}[data-hst-id="${hstId}"]`)
                    .addClass(mainSubMenuV1Semantics.subMenuWrapperActiveClass)
                    .css({height: '100rem'})
                    .show();
                subTab.siblings()
                    .removeClass(mainSubMenuV1Semantics.subMenuWrapperActiveClass)
                    .hide();

                //mvp for fast solve problem
                const tagBlockSelector = '.submenu__submenu-tag-block';
                var height = $(window).height()-$('.header-wrap').height();
                if(height < 600 ){
                    var cssHeight = (height-100)+'px';
                }else{
                    var cssHeight = '56rem';
                }
                $(subTab).masonry({
                    itemSelector: tagBlockSelector,
                    // gutter: 60,
                    horizontalOrder: true,
                    percentPosition: true,
                    singleMode: false,
                    isResizable: true,
                    animationOptions: {
                        queue: false,
                        duration: 0
                    },
                    containerStyle: {
                        'height': cssHeight,
                        'max-height': cssHeight,
                        'min-height': cssHeight,
                    }
                });



                current.siblings(`.${mainSubMenuV1Semantics.subMenuItemClass}`)
                    .removeClass(`.${mainSubMenuV1Semantics.subMenuItemActiveClass}`);

            }
        );
    }

    reInit(instanceId) {
        MainSubMenuV1.init();
    }
}

/**
 * Variables from frontend/assets/js/scripts/htmlTemplates.js
 */
/** @var string locationSwitcherWrapTemplate */
/** @var string locationSwitcherSearchTemplate */
/** @var string locationSwitcherListTemplate */
/** @var string locationSwitcherItemTemplate */
/** @var string catalogSubmenu2ndWrapTemplate */
/** @var string catalogSubmenu2ndMainListTemplate */
/** @var string catalogSubmenu2dMainItemTemplate */
/** @var string catalogSubmenu3rdMainListTemplate */
/** @var string catalogSubmenu3rdMainItemTemplate */
/** @var string catalogSubmenu3rdDropdownListTemplate */
/** @var string catalogSubmenu3rdDropdownItemTemplate */
/**
 * \Variables from frontend/assets/js/scripts/htmlTemplates.js
 */

$(() => {
    // only mobile and tablet (to landscape orientation)
    if (!matchMediaQuery('(max-width: 1024px)')) {
        return false;
    }
    
    // if API usage is required: just change the specification 
    // of the method by adding an object return
    let menuTracker = new PopoverMenuTracker();
});

/**
 * Tracker for popover (mobile/tablet) menu
 * @param Object semantics object with classes 
 *      of various interface elements in the format:
 *      ```js {
 *      semantics.openingButtonModifierClasses: 
 *          `array of classes of possible buttons for the opening event`,
 *      semantics.closingButtonModifierClasses: 
 *          `array of classes of possible buttons for the closing event`,
 *      semantics.menuWrapClass:
 *          `class of main wrapper html element`
 *      semantics.menuWrapOpenModifierClass:
 *          `class that determines whether an menu is open`
 *      semantics.rootElementClass:
 *          `class for page root html element for clear scroll-bar (e.g.: body/html/etc)`
 *      semantics.firstLvlModifierClass
 *          `class for detect all 1st lvl html element's of menu`
 *      semantics.secondLvlModifierClass
 *          `class for detect all 2nd lvl html element's of menu`
 *      semantics.secondLvlActivatorClass
 *          `class of the activator 2nd lvl menu`
 *      semantics.secondLvlActivatorAttribute
 *          `the attribute method of the activator`
 *      semantics.locationSwitcherActivatorDataWrap
 *          `class of the html element with cities`
 *      semantics.locationSwitcherActivatorItemSelector
 *          `selector of the city html element for retrieve payload`
 *      semantics.submenuWrapClass
 *          `class of the html element submenu`
 *      semantics.submenuWrapOpenModifierClass
 *          `modifier class of the opening submenu`
 *      semantics.submenu2ndLevelClass
 *          `class of the detect 2nd wrap level`
 *      semantics.submenu3rdLevelClass
 *          `class of the detect 3rd wrap level`
 *      semantics.submenu2ndCloseModifierClass
 *          `class of the close modifier 2nd level`
 *      semantics.submenu3rdCloseModifierClass
 *          `class of the close modifier 3rd level`
 *      }```
 */
class PopoverMenuTracker 
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor

    constructor() {
        this.semantics = {
            'openingButtonModifierClasses': [
                'js-popover-menu-opening'
            ],
            'openingAndFocusSearchButtonModifierClasses': [
                'js-popover-menu-opening-and-focus-search'
            ],
            'closingButtonModifierClasses': [
                'menu-bar__right-bar'
            ],
            'menuWrapClass': 'menu-bar__wrap',
            'menuWrapOpenModifierClass': 'menu-bar_is-open',
            'pageRootElementClass': 'body',
            'firstLvlModifierClass': 'menu-bar_is-popover-only-1st-lvl',
            'secondLvlModifierClass': 'menu-bar_is-popover-only-2nd-lvl',
            'secondLvlActivatorClass': 'js-2nd-level-activator',
            'thirdLvlActivatorClass': 'js-3rd-level-activator',
            'secondLvlActivatorAttribute': 'data-activator-method',
            'submenuWrapClass': 'menu-bar__submenu-wrap',
            'submenuWrapOpenModifierClass': 'menu-bar__submenu-wrap_is-open',
            'submenu2ndLevelClass': 'menu-bar__submenu-2nd-level',
            'submenu3rdLevelClass': 'menu-bar__submenu-3rd-level',
            'submenu2ndCloseModifierClass': 'js-2nd-submenu-close',
            'submenu3rdCloseModifierClass': 'js-3rd-submenu-close',
            // 2nd and 3rd
            'catalogSubmenuCommonSelector': '.submenu-wrap .submenu',
            
            'catalogMenu2ndItemClass': 'menu-bar__link',
            'catalogSubmenuWrapClass': 'submenu__wrapper',
            'catalogSubmenuWrapAttribute': 'data-hmt-id',
            'catalogSubmenu2ndDataWrap': 'submenu__left-bar',
            'catalogSubmenu2ndMainItemClass': 'submenu__main-menu-item',
            'catalogSubmenu2ndMainItemLinkClass': 'submenu__main-menu-link',
            'catalogSubmenu2ndSpecialPlaceClass': 'submenu__special-place',
            
            'catalogSubmenu3rdDataWrap': 'submenu__right-bar',
            'catalogSubmenu3rdWrapClass': 'submenu__submenu-wrapper',
            'catalogSubmenu3rdWrapAttribute': 'data-hst-id',
            'catalogSubmenu3rdBlockClass': 'submenu__submenu-tag-block',
            'catalogSubmenu3rdTitleClass': 'submenu__submenu-tag-title',
            'catalogSubmenu3rdItemClass': 'submenu__submenu-tag-link',
            'catalogSubmenu3rdItemWrapClass': 'submenu__submenu-tag-item',
            'catalogSubmenu3rdItemWrapDotModifier': 'submenu__submenu-tag-item_is-dot',
            'catalogSubmenu3rdItemDropdownItemClass': 'menu-bar__item-dropdown-item',
            'catalogSubmenu3rdDropdownItemDotModifier': 'menu-bar__item-dropdown-item_is-dot',
            'catalogSubmenu3rdSubmenuClass': 'menu-bar__item',
            'catalogSubmenu3rdSubmenuActiveModifier': 'menu-bar__item_is-active',

            // common menu
            'commonMenuItemClass': 'menu-bar__menu-item',
            'commonMenuItemActiveModifier': 'menu-bar__menu-item_is-active',

            // location switcher and search
            'locationSwitcherActivatorDataWrap': 'cities-popup__content',
            'locationSwitcherActivatorItemSelector': '.js-city-select',
            'locationFilteredWrapSelector': `.menu-bar__second-level-wrap_location-switcher ` +
                `.menu-bar__scroll-list .menu-bar__catalog-list`,
            'locationFilteredElementClass': 'menu-bar__item',
            'locationFilteredElementChildSelector': 'a.js-city-select',
            'locationFilteredInputSelector': `.menu-bar__second-level-wrap_location-switcher ` +
                `input.js-search-input`,
            'locationFilteredInputCloseWrapClass': 'search-form__close',
            'locationFilteredInputCloseIconClass': 'search-form__close-icon',
            
            // additional
            'searchInputSelector':  '.menu-bar__left-bar .menu-bar_is-popover-only-1st-lvl input.js-search-input',
            'searchFormSelector':  '.menu-bar__left-bar .menu-bar_is-popover-only-1st-lvl .search-form',
        };
        this.subscribe();
    }

    /**
     * Add listener to events
     * @return void
     */
    subscribe() {
        let self = this;
        // open wrapper
        $(`${self.semantics.openingButtonModifierClasses.map(
            className => `.${className}`
        ).join(', ')}`).on(
            'click', 
            function(e) {
                self.open();
            }
        );
        // open and focus search wrapper
        $(`${self.semantics.openingAndFocusSearchButtonModifierClasses.map(
            className => `.${className}`
        ).join(', ')}`).on(
            'click', 
            function(e) {
                // cancel relocation
                e.preventDefault();
                self.openAndFocusSearch();
                // and stop bubbling events (somewhere above, 
                // someone closes the popup window with search results)
                return false;
            }
        );
        // close wrapper
        $(`${self.semantics.closingButtonModifierClasses.map(
            className => `.${className}`
        ).join(', ')}`).on(
            'click', 
            function(e) {
                self.close();
            }
        );
        // close 2nd level (event on dynamic elements)
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu2ndLevelClass}`).on(
            'click',
            `.${self.semantics.submenu2ndCloseModifierClass}`,
            function(e) {
                e.preventDefault();
                self.close2ndSubmenu();
            }
        );
        // close 3rd level
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu3rdLevelClass}`).on(
            'click',
            `.${self.semantics.submenu3rdCloseModifierClass}`,
            function(e) {
                e.preventDefault();
                self.close3rdSubmenu();
            }
        );
        // activator's
        $(`.${self.semantics.secondLvlActivatorClass}`).on(
            'click',
            function(e) {
                e.preventDefault();
                let el = $(e.target).hasClass(self.semantics.secondLvlActivatorClass)
                    ? $(e.target)
                    : $(e.target).parents(`.${self.semantics.secondLvlActivatorClass}`);
                if (typeof 
                    self
                    [
                        el.attr(
                            self.semantics.secondLvlActivatorAttribute
                        )
                    ] === "function") { 
                    try {
                        // use activator...
                        self
                        [
                            el.attr(
                                self.semantics.secondLvlActivatorAttribute
                            )
                        ](el);
                        // ...and slide menu

                    } catch(error) {
                        console.error("Error when using the 2nd level menu activator: " + 
                            error.message);
                    }
                } else {
                    throw `Not found activator method [${el.attr(
                        self.semantics.secondLvlActivatorAttribute
                    )}]`;
                }
            }
        );
        // for all new added (live)
        $(document).on(
            'click',
            `.${self.semantics.thirdLvlActivatorClass}`,
            function(e) {
                e.preventDefault();
                let el = $(e.target).hasClass(self.semantics.thirdLvlActivatorClass)
                    ? $(e.target)
                    : $(e.target).parents(`.${self.semantics.thirdLvlActivatorClass}`);
                self.catalogSubmenu3rdActivator(el);
            }
        );
        //  'catalogSubmenu3rdSubmenuClass': 'menu-bar__item',
        // 'catalogSubmenu3rdSubmenuActiveModifier': 'menu-bar__item_is-active',
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu3rdLevelClass}`).on(
            'click',
            `.${self.semantics.catalogSubmenu3rdSubmenuClass}`,
            function(e) {
                if ($(e.target).parents(`.${self.semantics.catalogSubmenu3rdItemDropdownItemClass}`).length > 0) {
                    // just click on link
                    return true;
                }
                e.preventDefault();
                let el = $(e.target).hasClass(self.semantics.catalogSubmenu3rdSubmenuClass)
                    ? $(e.target)
                    : $(e.target).parents(`.${self.semantics.catalogSubmenu3rdSubmenuClass}`);
                if (el.hasClass(self.semantics.catalogSubmenu3rdSubmenuActiveModifier)) {
                    el.removeClass(self.semantics.catalogSubmenu3rdSubmenuActiveModifier);
                } else {
                    el.addClass(self.semantics.catalogSubmenu3rdSubmenuActiveModifier);
                }
            }
        )
    }

    /**
     * Activator for common submenu
     * @param JQuery html element activator-button
     * @return void
     * @throws
     */
    commonSubmenuActivator(el) {
        let self = this;
        let item = el.parents(`.${self.semantics.commonMenuItemClass}`);
        if (item) {
            if (item.hasClass(self.semantics.commonMenuItemActiveModifier)) {
                item.removeClass(self.semantics.commonMenuItemActiveModifier);
            } else {
                item.addClass(self.semantics.commonMenuItemActiveModifier);
            }
        }
    }

    /**
     * Activator for catalog 2nd submenu
     * @param JQuery html element activator-button
     * @return void
     * @throws
     */
    catalogSubmenu2ndActivator(el) {
        let self = this;
        let activatorElement = el;
        let wrap = $(
            `.${self.semantics.catalogSubmenuWrapClass}` + 
            `[${self.semantics.catalogSubmenuWrapAttribute}` + 
            `="${el.attr(self.semantics.catalogSubmenuWrapAttribute)}"]`
        );
        if (!wrap.length) {
            throw `Wrap by class [${self.semantics.catalogSubmenuWrapClass}]
                and attribute [${self.semantics.catalogSubmenuWrapAttribute}]
                with value [${el.attr(self.semantics.catalogSubmenuWrapAttribute)}]
                not found!`;
        }
        let dataWrap = wrap.find(`.${self.semantics.catalogSubmenu2ndDataWrap}`);
        if (!dataWrap.length) {
            throw `Data wrap by class [${self.semantics.catalogSubmenu2ndDataWrap}]
                not found!`;
        }
        let mainEls = [];
        dataWrap
            .find(`.${self.semantics.catalogSubmenu2ndMainItemLinkClass}`)
            .each((index, el) => {
                mainEls.push({
                    href: $(el).attr('href'),
                    text: $(el).text(),
                    allUrl:  $(el).data('allurl'),
                    allTitle: $(el).data('alltitle'),
                    'catalogSubmenuWrapAttribute': self.semantics.catalogSubmenuWrapAttribute,
                    'catalogSubmenuWrapAttributeValue': activatorElement.attr(
                        self.semantics.catalogSubmenuWrapAttribute
                    ),
                    'catalogSubmenu3rdWrapAttribute': self.semantics.catalogSubmenu3rdWrapAttribute,
                    'catalogSubmenu3rdWrapAttributeValue': 
                        $(el).parents(`.${self.semantics.catalogSubmenu2ndMainItemClass}`).attr(
                            self.semantics.catalogSubmenu3rdWrapAttribute
                        )
                });
            });
        let mainItems = mainEls.map((item) => {
            return stringInject(catalogSubmenu2dMainItemTemplate, item);
        });
        let mainList = stringInject(catalogSubmenu2ndMainListTemplate, {
            catalogSubmenuMainItems: mainItems.join('\n'),
        });
        let specialList = dataWrap.find(
            `.${self.semantics.catalogSubmenu2ndSpecialPlaceClass}`
        ).html();
        let content = stringInject(catalogSubmenu2ndWrapTemplate, {
            breadcrumb: el.find(`.${self.semantics.catalogMenu2ndItemClass}`).text(),
            catalogMainList: mainList,
            catalogSpecialList: specialList
        });
        self.setContent2ndLevel(content);
        self.open();
        self.open2ndSubmenu();
    }

    /**
     * Activator for catalog 3rd submenu
     * @param JQuery html element activator-button
     * @return void
     * @throws
     */
    catalogSubmenu3rdActivator(el) {
        let self = this;
        let wrap = $(
            `.${self.semantics.catalogSubmenuWrapClass}` + 
            `[${self.semantics.catalogSubmenuWrapAttribute}` + 
            `="${el.attr(self.semantics.catalogSubmenuWrapAttribute)}"]`
        );
        if (!wrap.length) {
            throw `Wrap by class [${self.semantics.catalogSubmenuWrapClass}]
                and attribute [${self.semantics.catalogSubmenuWrapAttribute}]
                with value [${el.attr(self.semantics.catalogSubmenuWrapAttribute)}]
                not found!`;
        }
        let dataWrap = wrap.find(`.${self.semantics.catalogSubmenu3rdDataWrap} .${self.semantics.catalogSubmenu3rdWrapClass}[${self.semantics.catalogSubmenu3rdWrapAttribute}=${el.attr(self.semantics.catalogSubmenu3rdWrapAttribute)}]`);
        if (!dataWrap.length) {
            throw `Data wrap by class [${self.semantics.catalogSubmenu3rdDataWrap}]
                not found!`;
        }
        let mainEls = [];
        dataWrap
            .find(`.${self.semantics.catalogSubmenu3rdBlockClass}`)
            .each((index, el) => {
                let subEls = [];
                $(el).find(`.${self.semantics.catalogSubmenu3rdItemClass}`).each((subIndex, subEl) => {
                    let subData = {
                        text: $(subEl).text(),
                        'href': $(subEl).attr('href'),
                    };
                    if ($(subEl).parents(`.${self.semantics.catalogSubmenu3rdItemWrapClass}`).
                        hasClass(
                            self.semantics.catalogSubmenu3rdItemWrapDotModifier
                        )) {
                        subData.dotModifier = self.semantics.catalogSubmenu3rdDropdownItemDotModifier;
                    } else {
                        subData.dotModifier = '';
                    }
                    subEls.push(subData);
                });
                mainEls.push({
                    text: $(el).find(`.${self.semantics.catalogSubmenu3rdTitleClass}`).text(),
                    elements: subEls,
                });
            });
        // build dropdown elements
        let mainItems = mainEls.map((item) => {
            let dropdownItems = item.elements.map((el) => {
                return stringInject(catalogSubmenu3rdDropdownItemTemplate, el);
            });
            let dropdownList = stringInject(catalogSubmenu3rdDropdownListTemplate, {
                'catalogSubmenuDropdownItems': dropdownItems.join('\n'),
            });
            return stringInject(catalogSubmenu3rdMainItemTemplate, {
                'catalogDropdownList': dropdownList,
                'text': item.text,
            });
        });
        console.log(el.attr('data-hst-id'));
        let mainList = stringInject(catalogSubmenu3rdMainListTemplate, {
            catalogSubmenuMainItems: mainItems.join('\n'),
            'allUrl':  $(el).data('allurl'),
            'allTitle': $(el).data('alltitle'),
        });
        let content = stringInject(catalogSubmenu3rdMainWrapTemplate, {
            breadcrumb: el.find(`a`).text(),
            catalogMainList: mainList,
        });
        self.setContent3rdLevel(content);
        self.open3rdSubmenu();
    }

    /**
     * Activator for location switcher
     * @param JQuery html element activator-button
     * @return void
     * @throws
     */
    locationSwitcherActivator(el) {
        let self = this;
        let cityList = [];
        $(`.${self.semantics.locationSwitcherActivatorDataWrap}`)
            .find(
                self.semantics.locationSwitcherActivatorItemSelector
            ).each((index, el) => {
                cityList.push({
                    cityId: $(el).attr('data-id'),
                    cityName: $(el).text()
                });
            });
        let items = cityList.map((city) => {
            return stringInject(locationSwitcherItemTemplate, city);
        });
        let list = stringInject(locationSwitcherListTemplate, {
            locationSwitcherItems: items.join('\n'),
        });
        let content = stringInject(locationSwitcherWrapTemplate, {
            locationSwitcherSearch: locationSwitcherSearchTemplate,
            locationSwitcherList: list
        });
        self.setContent2ndLevel(content);
        self.open();
        self.open2ndSubmenu();
        self.locationSwitcherSideEffects();
    }

    /**
     * Apply side effects after use activator
     */
    locationSwitcherSideEffects() {
        let self = this;
        // search plugin
        $(self.semantics.locationFilteredWrapSelector).btsListFilter(
            self.semantics.locationFilteredInputSelector,
            {
                'itemEl': `.` + self.semantics.locationFilteredElementClass,
                'itemChild': self.semantics.locationFilteredElementChildSelector,
                'casesensitive': false,
                'resetOnBlur': false,
                cancelNode: function() {
                    return `<div class="${self.semantics.locationFilteredInputCloseWrapClass}">
                        <svg class="${self.semantics.locationFilteredInputCloseIconClass}" 
                            width="16" height="16">
                            <use xlink:href="${iconSVG}#close-icon"></use>
                        </svg>
                    </div>`;
                },
                emptyNode: function(data) {
                    return `<div class="${self.semantics.locationFilteredElementClass}">
                        Ничего не найдено
                    </div>`;
                },
                'itemFilter': function(item, val) {
                    val = val && val.replace(new RegExp("[({[^.$*+?\\\]})]","g"),'');
                    let text = $(item).text().trim();
                    let regSearch = new RegExp(val, 'i');
                    return regSearch.test(text);
                }
            }
        );
    }

    /**
     * Open menu
     * @return void
     */
    open() {
        let self = this;
        $(`.${self.semantics.menuWrapClass}`).addClass(
            self.semantics.menuWrapOpenModifierClass
        );
        // close submenu's
        self.close3rdSubmenu();
        self.close2ndSubmenu();
        $(self.semantics.searchFormSelector).removeClass('_focused');
        // clear scroll-bar for root-page element
        $(`${self.semantics.pageRootElementClass}`).css({
            'overflow': 'hidden',
        });
    }

    /**
     * Open menu and focus to search input
     * @return void
     */
    openAndFocusSearch() {
        let self = this;
        self.open();
        $(self.semantics.searchInputSelector).trigger('focus');
        console.log($(self.semantics.searchFormSelector));
        $(self.semantics.searchFormSelector).addClass('_focused');
    }
    
    /**
     * Close menu
     * @return void
     */
    close() {
        let self = this;
        $(`.${self.semantics.menuWrapClass}`).removeClass(
            self.semantics.menuWrapOpenModifierClass
        );
        // clear scroll-bar for root-page element
        $(`${self.semantics.pageRootElementClass}`).css({
            'overflow': 'auto',
        });
    }

    /**
     * Replace content in 2nd level
     * @param string content
     * @return void
     */
    setContent2ndLevel(content) {
        let self = this;
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu2ndLevelClass}`).empty()
            .html(content);
    }

    /**
     * Replace content in 3rd level
     * @param string content
     * @return void
     */
    setContent3rdLevel(content) {
        let self = this;
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu3rdLevelClass}`).empty()
            .html(content);
    }

    /**
     * Open 2nd submenu
     * @return void
     */
    open2ndSubmenu() {
        let self = this;
        // mixin animations and decorations after tests
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu2ndLevelClass}`).addClass(
            self.semantics.submenuWrapOpenModifierClass
        );
    }

    /**
     * Open 3rd submenu
     * @return void
     */
    open3rdSubmenu() {
        let self = this;
        // mixin animations and decorations after tests
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu3rdLevelClass}`).addClass(
            self.semantics.submenuWrapOpenModifierClass
        );
    }
    
    /**
     * Close 2nd submenu
     * @return void
     */
    close2ndSubmenu() {
        let self = this;
        // mixin animations and decorations after tests
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu2ndLevelClass}`).removeClass(
            self.semantics.submenuWrapOpenModifierClass
        );
    }

    /**
     * Close 3rd submenu
     * @return void
     */
    close3rdSubmenu() {
        let self = this;
        // mixin animations and decorations after tests
        $(`.${self.semantics.submenuWrapClass}.${self.semantics.submenu3rdLevelClass}`).removeClass(
            self.semantics.submenuWrapOpenModifierClass
        );
    }
}