import $ from 'jquery';
import 'select/js/select2.full.min';

/** @module selectV2 */

class SelectV2
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    /**
     * Detect and init new elements
     */
    initAll() {
        let self = this;
        $(`.${selectV2Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized = 
                ($(el).attr(`${selectV2Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                self.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId = 
            el.attr(`${selectV2Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance select with id [${instanceId}]`);
        }
        let commonPluginOptions = 
            el.data(
                `${selectV2Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};
        let pluginOptions = Object.assign({},
            commonPluginOptions,
            {
            }
        );
        
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let select = el.find(
                `.${selectV2Semantics.elementClass}`
            ).select2(pluginOptions);

            // special's trigger on events
            el.on('select2:open', function(e) {
                var searchWrapEl = $('.select2-dropdown .select2-search');
                var searchInputEl = searchWrapEl.find('input.select2-search__field');
                searchInputEl.attr(
                    'placeholder', 
                    (commonPluginOptions.searchInputPlaceholder || '')
                );
                if (searchWrapEl.has('.select-v2__dropdown-icon-wrap').length === 0) {
                    searchWrapEl.append(
                        `<div class="select-v2__dropdown-icon-wrap">
                            ${commonPluginOptions.iconString}
                         </div>`
                    );
                }
            })

            el.attr(`${selectV2Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = select;
        } catch(e) {
            el.hide();
            throw e;
        }
    }
}

let selectV2 = new SelectV2();

/**
 * Select configs
 */
const selectV2Semantics = {
    'mainWrapClass': 'select-v2',
    'elementClass': 'select-v2__element',
    'instanceIdAttribute': 'data-instance-id',
    'instanceIdAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options'
};

/**
 * Select initializer
 */
$(function() {

    if ($(`.${selectV2Semantics.mainWrapClass}`).length === 0) {
        return false;
    }    
    selectV2.initAll();

});

export default selectV2;