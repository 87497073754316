import { size } from './config';
import $ from 'jquery';

$(() => {
    initSmoothScroll();
});

function initSmoothScroll() {
    $(document).on('click', '.js-smooth-scroll', function(e) {
        e.preventDefault();
        scroll('#send-request-block');
    });
}

function scroll(id, offset = 0) {
    {
        const $target = $(id);
        if ($target.length) {
            // e.preventDefault();

            let scrollTop
            if(window.innerWidth < size.tablet) {
                scrollTop = $target.offset().top - $('.js-sticky-header').height();
            } else {
                scrollTop = $target.offset().top;
            }
            // location.hash = id;
            $('html, body').animate({
                scrollTop: scrollTop + offset,
            }, 1000);
        }
    }
}

export {
    scroll,
    initSmoothScroll
};
