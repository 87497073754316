import LIVR from 'livr';

var russianMobilePhone = function(value) {
    if (!value) throw "[value] parameter required";

    return function(val) {
        let clearVal = val.replace(/\D/g, '');
        if (clearVal[0] == '7') {
            clearVal = clearVal.slice(1);
        }
            
        if (clearVal.length !== 10 || clearVal[0] != '9') {
            return 'NO_RUSSIAN_MOBILE_PHONE';
        }

        return;
    }
};

var russianPhone = function(value) {
    if (!value) throw "[value] parameter required";

    return function(val) {
        let clearVal = val.replace(/\D/g, '');
        if (clearVal[0] == '7') {
            clearVal = clearVal.slice(1);
        }
            
        if (clearVal.length !== 10) {
            return 'NO_RUSSIAN_PHONE';
        }

        return;
    }
};

export default LIVR.Validator.registerDefaultRules({
    russian_mobile_phone: russianMobilePhone,
    russian_phone: russianPhone,
});