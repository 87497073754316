import $ from 'jquery';
import { 
    CompareModule 
} from "../../services/compare/Compare";
import { 
    WishlistModule 
} from "../../services/wishlist/Wishlist";
import { 
    CartModule 
} from '../../services/cart/Cart';

/**
 * ExtraDataV1 widget configs
 */
const extraDataV1Semantics = {
    'dataUrl': '/extra-data/get',
};

/**
 * ExtraDataV1 initializer
 */
$(function () {
    ExtraDataV1.init();
});

class ExtraDataV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if (!window || !window.widgets || !window.widgets.extraDataV1) {
            window.widgets.extraDataV1 = new ExtraDataV1();
        }
        window.widgets.extraDataV1.getActualData();
    }

    getActualData() {
        CartModule.cart.updateCounterTracker()
        WishlistModule.list.updateCounterTracker()
        CompareModule.list.updateCounterTracker()
        /* not deleted please  - for get data from server */
        /* $.get(extraDataV1Semantics.dataUrl, function (data) {
             window.widgets.extraDataV1.processData(data);
         });*/
    }

    processData(data) {
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof this[key] === "function") {
                this[key](data[key]);
            }
        }
    }

    compareCounter(data) {
        // CartModule.cart.updateCounterTracker()
    }

    basketCounter(data) {
        //console.log('basketCounter', data);
    }

    wishListCounter(data) {
        // console.log('wishListCounter', data);
    }

    reInit(instanceId) {
        ExtraDataV1.init();
    }
}