import $ from 'jquery';
import 'slick';
import { 
    size,
    slickCommonOptions,
} from '../../scripts/config';

/**
 * Slider configs
 */
const showroomV2Semantics = {
    'mainWrapClass': 'showroom-v2__wrap',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'showroom-v2__prev',
    'controlNextClass': 'showroom-v2__next',
    'sliderElementClass': 'showroom-v2__items', 
    'slideElementClass': 'showroom-v2__slide', 
    'listElementClass': 'showroom-v2__list',
    'dotBarWrapClass': 'showroom-v2__dots-bar-place',
};

/**
 * Slider initializer
 */
$(function() {

    if ($(`.${showroomV2Semantics.mainWrapClass}`).length === 0) {
        return false;
    }

    let showroomV2 = new ShowroomV2();

    /**
     * Checking other instances
     */
    $(`.${showroomV2Semantics.mainWrapClass}`).each((index, el) => {
        let isInitialized = 
            ($(el).attr(`${showroomV2Semantics.isInitializedAttribute}`) === 'true');
        if (!isInitialized) {
            showroomV2.registerInstance($(el));
            showroomV2.registerListeners($(el));
        }
    });

    // TODO[justMvp]: внедрить универсальное решение
    // для переинициализации дочерних slick-слайдеров
    // внутри родительских
    setTimeout(() => {
        if (window.widgets.galleryV3) {
            window.widgets.galleryV3.resetInitialized(
                $(`.${showroomV2Semantics.mainWrapClass}`)
            );
            window.widgets.galleryV3.initAll(
                $(`.${showroomV2Semantics.mainWrapClass}`)
            );
        }
    }, 1);

});

class ShowroomV2
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
        this.countReRun = 0;
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId = 
            el.attr(`${showroomV2Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions = 
            el.data(
                `${showroomV2Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                // infinite: true,
                // fade: false,
                // variableWidth: true,
                arrows: true,
                mobileFirst: true,
                swipeToSlide: true,
                slidesToShow: 1,
                prevArrow: `.${showroomV2Semantics.mainWrapClass}` + 
                    `[${showroomV2Semantics.instanceIdAttribute}="` + 
                    el.attr(`${showroomV2Semantics.instanceIdAttribute}`) + 
                    `"] .${showroomV2Semantics.controlPrevClass}`,
                nextArrow: `.${showroomV2Semantics.mainWrapClass}` + 
                    `[${showroomV2Semantics.instanceIdAttribute}="` + 
                    el.attr(`${showroomV2Semantics.instanceIdAttribute}`) + 
                    `"] .${showroomV2Semantics.controlNextClass}`,
                responsive: [
                    {
                        breakpoint: size.tablet-1,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: size.desktop-1,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                ]
            }
        );

        el.show();

        let isUseSlick = true;
        let slidesCount = 
            el.find(`.${showroomV2Semantics.slideElementClass}`)
            .length;
        let slidesToShow = (
            window.innerWidth 
                >= 
            size.smDesktop 
                ? 3 
                : window.innerWidth 
                    >= 
                size.tablet ? 2 : 1 
        );
        let slidesWidth = (
            el.find(`.${showroomV2Semantics.listElementClass}`)
                .parent().width() - 24 * (slidesToShow - 1)
            )
                / 
            slidesToShow * (
                window.innerWidth >= size.smDesktop ? 1 : 0.9
            );

        if (
            window.innerWidth 
                < 
            size.tablet && slidesCount <= 1
        ) {
            isUseSlick = false;
        }
        if (
            window.innerWidth 
                >= 
            size.tablet && window.innerWidth 
                < 
            size.smDesktop && slidesCount <= 2
        ) {
            isUseSlick = false;
        }
        if (
            window.innerWidth 
                >= 
            size.smDesktop && slidesCount <= 3
        ) {
            isUseSlick = false;
        }

        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            el.attr(`${showroomV2Semantics.isInitializedAttribute}`, true);
            if (isUseSlick === true) {
                let slick = el.find(
                    `.${showroomV2Semantics.listElementClass}`
                ).slick(pluginOptions);
                this.pluginInstances[instanceId] = slick;

                // el.find(`.${showroomV2Semantics.slideElementClass}`).css('width', slidesWidth);
            } else {
                this.pluginInstances[instanceId] = el;
            }
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    /**
     * Register listeners
     * @param JQuery element
     * @throws
     */
    registerListeners(el) {
        // el.on('afterChange', function (event, slick, currentSlide) {
        //     if (window.widgets.galleryV3) {
        //         window.widgets.galleryV3.resetInitialized();
        //         window.widgets.galleryV3.initAll();
        //     }
        // });
    }
}