import $ from 'jquery';
import 'slick';
import { 
    size,
    slickCommonOptions,
} from './../../scripts/config';

/**
 * ImageCarouselV1 widget configs
 */
const imageCarouselV1Semantics = {
    'mainWrapClass': 'image-carousel-v1__wrap',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'sliderElementClass': 'image-carousel-v1__container',
    'countSlides': 'data-count-slides',
};

/**
 * ImageCarouselV1 initializer
 */
$(function() {
    ImageCarouselV1.init();
});

class ImageCarouselV1
{
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${imageCarouselV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.imageCarouselV1) {
            window.widgets.imageCarouselV1 = new ImageCarouselV1();
        }

        /**
         * Checking other instances
         */
        $(`.${imageCarouselV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${imageCarouselV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.imageCarouselV1.registerInstance($(el));
            }
        });
    }
    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId = 
            el.attr(`${imageCarouselV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions = 
            el.data(
                `${imageCarouselV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: false,
                infinite: true,
                slidesToShow: 6,
                responsive: [
                    {
                        breakpoint: size.tablet - 1,
                        settings: {
                            slidesToShow: 2,

                        }
                    },
                    {
                        breakpoint: size.desktop - 1,
                        settings: {
                            slidesToShow: 4,

                        }
                    },
                    {
                        breakpoint: size.desktopMax - 1,
                        settings: {
                            slidesToShow: 5,
                        }
                    },
                ],
            }
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${imageCarouselV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);            
            el.find(
                `.${imageCarouselV1Semantics.sliderElementClass}`
            ).removeClass('_hide');
            el.attr(`${imageCarouselV1Semantics.isInitializedAttribute}`, true);
            window.widgets.imageCarouselV1[instanceId] = slick;
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    reInit(instanceId) {
        ImageCarouselV1.init();
    }
}